import clsx from 'clsx';
import React from 'react'

interface TabLinkProps {
    readonly tab: {
        id: number;
        title: string;
    };
    readonly active: boolean;
    readonly completed: boolean;
}

export default function TabLink({ tab, active, completed }: TabLinkProps) {
    return (
        <div className="flex flex-row gap-[12px] items-center">
            <div className={clsx("w-[32px] h-[32px] rounded-full flex items-center text-gray-100 border border-gray-100 justify-center text-sm leading-[21px]", { "bg-primary text-white border-none": active, "border-primary bg-primary100 text-primary": completed })}>{tab.id}</div>
            <p className={clsx("mb-0 text-sm leading-[21px] text-gray-100", { "text-black": active || completed })}>{tab.title}</p>
        </div>
    )
}
