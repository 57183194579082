import clsx from 'clsx';
import React from 'react'
import { Link } from 'react-router-dom';
import closeIcon from 'assets/svgs/ic_close.svg'

interface NavMenuProps {
    readonly options: {
        title: string;
        icon: React.ReactNode;
        link: string;
        removable?: boolean;
        isActive?: boolean;
    }[]
}

export default function NavMenu({ options }: NavMenuProps) {
  return (
    <div className="flex flex-row bg-white box-shadow-[2px_2px_6px_0px_#EDEFF2B2] w-fit shadow">
        {
            options.map((option, index) => (
                <Link to={option.link} key={index} className={clsx("relative flex flex-row items-center gap-[6px] px-4 uppercase text-sm font-normal text-secondary leading-[21px] py-2 hover:bg-[#F4F5F7] group", {
                    "border-b-[2px] border-primary": option.isActive
                })}>
                    {option.icon}
                    <span className={clsx({"text-primary font-semibold text-nowrap": option.isActive})}>{option.title}</span>
                    {
                        option.removable && (
                            <>
                                <button className="items-center justify-end absolute right-0 top-0 w-[24px] pr-[2px] h-full z-[2] hidden group-hover:flex">
                                    <img src={closeIcon} alt="close" className="size-[20px]" />
                                </button>
                                <div className="absolute h-full bg-[#F4F5F7] opacity-50 right-0 top-0 w-[30px] hidden group-hover:flex"></div>
                            </>
                        )
                    }
                </Link>
            ))
        }
    </div>
  )
}
