import { TableHeader } from "app/components/Table/types";
import { TicketType } from "../../types";
import { BacklogTicket } from "./types";

export const HEADERS: TableHeader[] = [
    {
        name: "Name",
        id: "name",
        className: "!p-[4px] py-[12px]"
    },
    {
        name: "Epic",
        id: "epic",
        className: "!p-[4px] py-[12px]"
    },
    {
        name: "Release",
        id: "release",
        className: "!p-[4px] py-[12px]"
    },
    {
        name: "Sprint",
        id: "sprint",
        className: "!p-[4px] py-[12px]"
    }
]

export const TICKETS: BacklogTicket[] = [
    {
        id: "000001",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "internal bug",
                color: "#FF1A7F",
                backgroundColor: "#FF1A7F2E"
            },
            {
                name: "change request",
                color: "#FEC700",
                backgroundColor: "#FEC7002E"
            }
        ],
        type: TicketType.STORY,
        release: "1.0",
        sprint: "Operation Warp Speed",
        epic: {
            name: "Feature Factory",
            color: "#9B51E0",
        }
    },
    {
        id: "000002",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "figma needed",
                color: "#00ABE5",
                backgroundColor: "#00ABE52E"
            },
            {
                name: "design",
                color: "#00AB7B",
                backgroundColor: "#00AB7B2E"
            }
        ],
        type: TicketType.TASK,
        release: "2.0",
        sprint: "Code Crusaders",
        epic: {
            name: "Integration Innovators",
            color: "#2F80ED",
        }
    },
    {
        id: "000003",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "figma needed",
                color: "#00ABE5",
                backgroundColor: "#00ABE52E"
            }
        ],
        type: TicketType.STORY,
        release: "1.0",
        sprint: "Operation Warp Speed",
        epic: {
            name: "Feature Factory",
            color: "#9B51E0",
        }
    },
    {
        id: "000004",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "figma needed",
                color: "#00ABE5",
                backgroundColor: "#00ABE52E"
            }
        ],
        type: TicketType.STORY,
        release: "1.0",
        sprint: "Operation Warp Speed",
        epic: {
            name: "Feature Factory",
            color: "#9B51E0",
        }
    },
    {
        id: "000005",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "figma needed",
                color: "#00ABE5",
                backgroundColor: "#00ABE52E"
            }
        ],
        type: TicketType.STORY,
        release: "1.0",
        sprint: "Operation Warp Speed",
        epic: {
            name: "Feature Factory",
            color: "#9B51E0",
        }
    },
    {
        id: "000006",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "figma needed",
                color: "#00ABE5",
                backgroundColor: "#00ABE52E"
            }
        ],
        type: TicketType.STORY,
        release: "1.0",
        sprint: "Operation Warp Speed",
        epic: {
            name: "Feature Factory",
            color: "#9B51E0",
        }
    },
    {
        id: "000007",
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        labels: [
            {
                name: "figma needed",
                color: "#00ABE5",
                backgroundColor: "#00ABE52E"

            }
        ],
        type: TicketType.STORY,
        release: "1.0",
        sprint: "Operation Warp Speed",
        epic: {
            name: "Feature Factory",
            color: "#9B51E0",
        }
    },
]