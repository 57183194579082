import DropdownIcon from 'app/components/Icons/DropdownIcon'
import RightCaretIcon from 'app/components/Icons/RightCaretIcon'
import React from 'react'

interface ToggleButtonProps {
    isActive: boolean
    onClick: () => void
    label?: string
}

export default function ToggleButton({ isActive, onClick, label="Show previous releases" }: ToggleButtonProps) {
    return (
        <button 
            onClick={onClick}
            className="flex flex-row items-center gap-[6px]"
        >
            {
                isActive ? (
                    <DropdownIcon color="#5A8DF1" />
                ) : (
                    <RightCaretIcon color="#5A8DF1" />
                )
            }
            <span className="text-sm leading-[24px] text-primary font-semibold font-sans">{label}</span>
        </button>
    )
}