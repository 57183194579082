import React, { useState } from 'react'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import Story from '../components/Story'
import StoryIcon from 'app/components/Icons/StoryIcon'
import { getTicketIcon } from '../../Home/containers/helpers'
import { TicketType } from '../../../types'
import AddStoryModal from '../../../components/AddStoryModal'

const STORIES = [
    {
        title: {
            name: "Implement User Authentication with Google OAuth for Account Creation and Login",
            color: "#253858"
        },
        task: '5',
        bug: '2',
    },
    {
        title: {
            name: "Integration Innovators",
            color: "#253858"
        },
        task: '5',
        bug: '2',
    },
    {
        title: {
            name: "Integration Innovators_1.0.1",
            color: "#253858"
        },
        task: '5',
        bug: '2',
    },
]

export default function Stories() {
    const [isAddStoryModalOpen, setIsAddStoryModalOpen] = useState(false)
    return (
        <div className="flex flex-col gap-[10px]">
            <Story 
                icon={<StoryIcon size={16} isCanceled={true} color="#253858" />}
                title="Tickets without story"
                task="5"
                bug="2"
                color="#253858"
            />
            {STORIES.map((epic, index) => (
                <Story 
                    icon={<img src={getTicketIcon(TicketType.STORY)} alt="story" className="size-[16px]" />}
                    key={index} 
                    title={epic.title.name} 
                    task={epic.task} 
                    bug={epic.bug} 
                    color={epic.title.color}
                />
            ))}
            <button className="flex flex-row w-fit items-center gap-[4px]" onClick={() => setIsAddStoryModalOpen(true)}>
                <img src={addIcon} alt="add" className="size-[20px]" />
                <span className="text-sm leading-[24px] text-primary font-semibold mt-[2px] font-sans">Story</span>
            </button>
            {
                isAddStoryModalOpen && (
                    <AddStoryModal onClose={() => setIsAddStoryModalOpen(false)} />
                )
            }
        </div>
    )
}