import React from 'react'
import NavMenu from 'app/components/NavMenu'
import { useLocation } from 'react-router-dom'
import Breadcrumb from 'app/containers/Features/components/Breadcrumb'
import CreateTaskButton from './CreateTaskButton'
import starIcon from 'assets/svgs/ic_star.svg'
import menuIcon from 'assets/svgs/ic_menu-grey.svg'
import dropdownIcon from 'assets/svgs/ic_caret_down.svg'
import ListIcon from 'app/components/Icons/ListIcon'
import KabanIcon from 'app/components/Icons/KabanIcon'
import BacklogIcon from 'app/components/Icons/BacklogIcon'
import HomeIcon from 'app/components/Icons/HomeIcon'
import AddBoard from './AddBoard'
import LetterCard from 'app/components/LetterCard'
import TicketsIcon from 'app/components/Icons/TicketsIcon'

const traces = [
    {
        title: 'Digital Innovators Collective team',
        icon: <LetterCard className="!size-[16px] !min-w-[16px]" letterClassName="!text-secondary text-[8px] leading-[12px]" letter="D" backgroundColor="#B3E9CA" color="#79808A" />,    
        link: '/app/1/1/boards'
    },
    {
        title: 'TechMate project',
        icon: <LetterCard className="!size-[16px] !min-w-[16px]" letterClassName="!text-secondary text-[8px] leading-[12px]" letter="T" backgroundColor="#B3E8E9" color="#79808A" />,
        link: '/app/1/1/boards'
    },
    {
        title: 'Tickets',
        icon: <TicketsIcon color="#253858" size={16} />,
        link: '/app/1/1/boards'
    }
]

export default function Layout({ children }: { children: React.ReactNode }) {
    const location = useLocation();
    const pathname = location.pathname;
    const options = [
        {
            title: 'home',
            icon: <HomeIcon size={20} color={pathname === '/app/1/1/boards' ? "#5A8DF1" : "#79808A"} />,
            link: '/app/1/1/boards',
            isActive: pathname === '/app/1/1/boards'
        },
        {
            title: 'Backlog',
            icon: <BacklogIcon color={pathname === '/app/1/1/boards/backlog' ? "#5A8DF1" : "#79808A"} />,
            link: '/app/1/1/boards/backlog',
            isActive: pathname === '/app/1/1/boards/backlog'
        },
        {
            title: "QA bugs Board",
            icon: <ListIcon color={pathname.startsWith('/app/1/1/boards/2/') && !pathname.startsWith('/app/1/1/boards/backlog') ? "#5A8DF1" : "#79808A"} />,
            link: '/app/1/1/boards/2/board',
            removable: true,
            isActive: pathname.startsWith('/app/1/1/boards/2/') && !pathname.startsWith('/app/1/1/boards/backlog')
        },
        {
            title: "Dev Board",
            icon: <KabanIcon color={pathname.startsWith('/app/1/1/boards/3/') && !pathname.startsWith('/app/1/1/boards/backlog') ? "#5A8DF1" : "#79808A"} />,
            link: '/app/1/1/boards/3/board',
            isActive: pathname.startsWith('/app/1/1/boards/3/') && !pathname.startsWith('/app/1/1/boards/backlog'),
            removable: true
        },
        {
            title: "Dev Tasks Board",
            icon: <KabanIcon />,
            link: '',
            removable: true
        }
        
    ]
    return (
        <div className="flex flex-col gap-3 overflow-x-auto relative">
            <Breadcrumb 
                traces={traces}
            />
            <div className="flex flex-row justify-between items-center min-w-max flex-grow">
                <div className="flex flex-row gap-[6px] items-center">
                    <NavMenu
                        options={options}
                    />
                    <AddBoard />
                </div>
                <div className="flex flex-row gap-[16px] items-center">
                    <button className="w-[24px] h-[24px] flex items-center justify-center">
                        <img src={starIcon} alt="StarIcon" className="w-[19px] h-[18px]" />
                    </button>
                    <button className="w-[24px] h-[24px]">
                        <img src={menuIcon} alt="StarIcon" className="size-[24px]" />
                    </button>
                    <button className="w-[24px] h-[24px]">
                        <img src={dropdownIcon} alt="StarIcon" className="size-[24px]" />
                    </button>
                    <CreateTaskButton />
                </div>
            </div>
            <div className="overflow-x-auto">
                {children}
            </div>
        </div>
    )
}
