import { FormElement } from "app/types";

export enum PLANS {
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export interface PLAN {
    title: PLAN_TYPE;
    caption: string;
    price: number;
    features: string[];
}

export enum PLAN_TYPE {
    FREE = 'free',
    BUSINESS = 'business',
    PROFESSIONAL = 'professional',
}

export interface OnBoardingState {
    profileForm: ProfileForm;
    setupForm: SetupForm;
    teamForm: TeamForm;
}

export type ProfileForm = {
    name: FormElement;
    password: FormElement;
    confirmPassword:FormElement;
    isLoading: boolean;
}

export type SetupForm = {
    companySize: FormElement;
    referalSource: FormElement;
    usedTools: FormElement;
    isLoading: boolean;
}

export type TeamForm = {
    name: FormElement;
    members: TeamMember[];
    plan: PLAN_TYPE;
    isLoading: boolean;
}

export type TeamMember = {
    name: FormElement;
    role: FormElement;
}

export type ContainerState = OnBoardingState;