import React from 'react'

interface PlusIconProps {
    color?: string
    size?: number
}

export default function PlusIcon({ color="#79808A", size=20 }: PlusIconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/pluc">
                <path id="Vector" d="M15.7169 10.955H10.955V15.7169C10.955 15.9695 10.8546 16.2117 10.676 16.3903C10.4974 16.5689 10.2552 16.6693 10.0026 16.6693C9.75002 16.6693 9.50778 16.5689 9.32917 16.3903C9.15056 16.2117 9.05022 15.9695 9.05022 15.7169V10.955H4.28832C4.03573 10.955 3.79349 10.8546 3.61488 10.676C3.43628 10.4974 3.33594 10.2552 3.33594 10.0026C3.33594 9.75002 3.43628 9.50778 3.61488 9.32917C3.79349 9.15056 4.03573 9.05022 4.28832 9.05022H9.05022V4.28832C9.05022 4.03573 9.15056 3.79349 9.32917 3.61488C9.50778 3.43628 9.75002 3.33594 10.0026 3.33594C10.2552 3.33594 10.4974 3.43628 10.676 3.61488C10.8546 3.79349 10.955 4.03573 10.955 4.28832V9.05022H15.7169C15.9695 9.05022 16.2117 9.15056 16.3903 9.32917C16.5689 9.50778 16.6693 9.75002 16.6693 10.0026C16.6693 10.2552 16.5689 10.4974 16.3903 10.676C16.2117 10.8546 15.9695 10.955 15.7169 10.955Z" fill={color}/>
            </g>
        </svg>

    )
}