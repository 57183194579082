import React from 'react'

export default function UserIcon({
    color="#79808A",
    size = 16
}: {
    readonly color?: string
    readonly size?: number
}) {
    return (
        <svg width={size} height={size} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M6.00141 5.79969C7.3268 5.79969 8.40125 4.72524 8.40125 3.39984C8.40125 2.07445 7.3268 1 6.00141 1C4.67601 1 3.60156 2.07445 3.60156 3.39984C3.60156 4.72524 4.67601 5.79969 6.00141 5.79969Z" stroke={color}/>
                <path id="Vector_2" d="M10.8025 10.3014C10.8025 11.7923 10.8025 13.0012 6.00281 13.0012C1.20312 13.0012 1.20312 11.7923 1.20312 10.3014C1.20312 8.81048 3.35218 7.60156 6.00281 7.60156C8.65344 7.60156 10.8025 8.81048 10.8025 10.3014Z" stroke={color}/>
            </g>
        </svg>
    )
}