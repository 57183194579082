import Input from "app/components/Input"
import Textarea from "app/components/Icons/Textarea"
import React, { useState } from "react"
import DateRangePicker from "app/components/DateRangePicker"
import { DateRange } from "react-day-picker"

export default function AddSprint() {
    const [dateRange, setDateRange] = useState<DateRange>({
        from: undefined,
        to: undefined
    })
    return (
        <div className="flex flex-col px-[24px] py-4 pt-[12px] gap-4">
            <div className="flex flex-col gap-y-4">
                <Input
                    label="Sprint name"
                    placeholder="Enter sprint name..."
                    id='sprint-name'
                    inputClassName='!h-[48px] !gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                />
                <DateRangePicker
                    label="Start - End dates"
                    startDate={dateRange.from}
                    endDate={dateRange.to}
                    onChange={(dateRange) => {
                        setDateRange(dateRange)
                    }}
                    innerContainerClassName="!gap-[0px]"
                />
                <Textarea
                    label="Sprint description"
                    placeholder="Enter sprint description"
                    id='sprint-description'
                    inputClassName='!gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                />
            </div>
        </div>
    )
}