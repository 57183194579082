import Input from "app/components/Input"
import Textarea from "app/components/Icons/Textarea"
import { Select } from "app/components/Select"
import React from "react"
import DatePicker from "app/components/DatePicker"

export default function AddRelease() {
    return (
        <div className="flex flex-col px-[24px] py-4 pt-[12px] gap-4">
            <div className="flex flex-col gap-y-4">
                <Input
                    label="Release name"
                    placeholder="Enter release name..."
                    id='release-name'
                    inputClassName='!h-[48px] !gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                />
                <div className="grid grid-cols-2 gap-x-[10px]">
                    <DatePicker
                        label="Start date"
                        value={new Date()}
                        onChange={() => {}}
                        innerContainerClassName="!gap-[0px]"
                    />
                    <DatePicker
                        label="Release date"
                        value={new Date()}
                        onChange={() => {}}
                        innerContainerClassName="!gap-[0px]"
                        align="end"
                    />
                </div>
                <Select
                    label="Assignee"
                    placeholder="Select assignee..."
                    id='assignee'
                    inputClassName='!h-[48px] !gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                    options={[]}
                    value=""
                />
                <Textarea
                    label="Release description"
                    placeholder="Enter release description"
                    id='release-description'
                    inputClassName='!gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                />
            </div>
        </div>
    )
}