import NavMenu from 'app/components/NavMenu'
import Table from 'app/components/Table';
import React from 'react'
import Rows from './Rows';
import { TableHeader } from 'app/components/Table/types';

const options = [
    {
        title: 'all',
        icon: null,
        link: '/app/boards'
    },
    {
        title: 'my boards',
        icon: null,
        link: '/app/boards'
    }
]

const headers: TableHeader[] = [
    {
        name: "Board name",
        id: "boardName",
        align: "left",
        width: "200px"
    },
    {
        name: "Team name",
        id: "teamName",
        align: "left"
    },
    {
        name: "Project name",
        id: "projectName",
        align: "left"
    },
    {
        name: "Last viewed",
        id: "lastViewed",
        align: "left"
    },
    {
        name: "Last updated",
        id: "lastUpdated",
        align: "left"
    },
    {
        name: "Owner",
        id: "owner",
        align: "left",
    },
    {
        name: "Date created",
        id: "dateCreated",
        align: "left"
    }
]

export default function List() {
    return (
        <div className="flex flex-col gap-[10px]">
            <NavMenu
                options={options}
            />
            <Table
                headers={headers}
            >
                <Rows />
            </Table>
        </div>
    )
}
