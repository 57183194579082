import Card from 'app/components/Card'
import React from 'react'
import { getTicketIcon } from '../containers/helpers'
import descriptionIcon from "assets/svgs/ic_description.svg"
import workflowIcon from "assets/svgs/ic_tree.svg"
import attachmentIcon from "assets/svgs/ic_attachment.svg"
import historyIcon from "assets/svgs/ic_checklist.svg"
import LabelIcon from 'app/components/Icons/LabelIcon'
import Pill from 'app/components/Pill'
import UserIcon from 'app/components/Icons/UserIcon'
import Avatar from 'app/containers/Features/components/Avatar'
import { Ticket } from '../../../types'

interface TicketProps {
    ticket: Ticket
}

export default function TicketCard({ ticket }: TicketProps) {
    return (
        <Card key={ticket.id} className="rounded-[6px] pl-[10px] pr-[8px] py-[12px] cursor-pointer hover:border-primary hover:border group">
            <div className="flex flex-col gap-[12px]">

                <div className="flex flex-row gap-[4px]">
                    <div className="min-w-[20px]">
                        <img src={getTicketIcon(ticket?.type)} alt="task-icon" className="size-[16px]" />
                    </div>
                    <div className="text-xs leading-[15px] font-normal text-black text-wrap line-clamp-2 group-hover:!text-primary">{ticket?.name}</div>
                </div>

                <div className="flex flex-row items-center justify-start gap-[4px]">
                    <div className="min-w-[16px]">
                        <img src={descriptionIcon} alt="task-icon" className="size-[16px]" />
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="min-w-[16px]">
                            <img src={workflowIcon} alt="task-icon" className="size-[16px]" />
                        </div>
                        <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{ticket?.membersCount}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="min-w-[16px]">

                            <img src={attachmentIcon} alt="task-icon" className="size-[16px]" />
                        </div>
                        <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{ticket?.attachmentsCount}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="min-w-[16px]">
                            <img src={historyIcon} alt="task-icon" className="size-[16px]" />
                        </div>
                        <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{`${ticket?.completedCount}/${ticket?.totalCount}`}</div>
                    </div>
                </div>

                <div className="flex flex-row justify-start gap-[4px]">
                    <LabelIcon size={16} color="#79808A" />
                    <div className="flex flex-row flex-wrap gap-[4px]">
                        {
                            ticket?.labels.map((label) => (
                                <Pill key={label.name} className="!px-[6px]" backgroundColor={label.backgroundColor} textColor={label.color} name={label.name} />
                            ))
                        }
                    </div>
                </div>

                <div className="flex flex-row items-center justify-start gap-[4px]">
                    <UserIcon size={16} color="#79808A" />
                    <Avatar 
                        color={ticket?.assignee?.color} 
                        name={ticket?.assignee?.name} 
                        circleClassName="text-[6.4px] leading-[19.2px] font-normal size-[12.8px]" 
                        statusClassName="size-[3.2px]"
                        nameClassName="text-xs leading-[18px] font-normal text-secondary"
                    />
                </div>

            </div>
        </Card>
    )
}