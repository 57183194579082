import React, { FC } from "react";
import { getTicketIcon } from "../containers/Home/containers/helpers";
import { TicketType } from "../types";

interface TicketTypeButtonProps {
    readonly type: TicketType
}

export const TicketTypeButton: FC<TicketTypeButtonProps> = ({ type }) => {
    return (
        <button className="flex flex-row items-center gap-[4px] h-[28px] px-[6px] capitalize rounded-[4px] border border-[#E9ECF2] text-xs leading-[18px] font-medium text-secondary">
            <div className="min-w-[16px]">
                <img src={getTicketIcon(type)} alt="task-icon" className="size-[16px]" />
            </div>
            {type}
        </button>
    )
}