/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Login from "./containers/Auth/containers/login";
import ForgotPassword from "./containers/Auth/containers/forgotPassword";
import RecoverySuccess from "./containers/Auth/containers/recoverySuccess";
import Signup from "./containers/Auth/containers/signup";
import VerifyEmail from "./containers/Auth/containers/verifyEmail";
import CreateProfile from "./containers/OnBoarding/containers/Profile";
import Welcome from "./containers/OnBoarding/containers/Profile/components/welcome";
import Setup from "./containers/OnBoarding/containers/Profile/components/setup";
import TeamOnBoarding from "./containers/OnBoarding/containers/Team";
import Homepage from "./containers/Features/containers/Home";
import Boards from "./containers/Features/containers/Boards";
import BoardView from "./containers/Features/containers/Boards/containers/Home/containers/Board";
import Backlog from "./containers/Features/containers/Boards/containers/Backlog";
import { Auth } from "./containers/Auth/Loadable";
import { useInjectReducer } from "utils/redux-injectors";
import { appReducer, appSlice } from "./redux/slice";
import { OnBoarding } from "./containers/OnBoarding/Loadable";
import { Features } from "./containers/Features/Loadable";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth/login" />,
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "recovery-success",
        element: <RecoverySuccess />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      }
    ]
  },
  {
    path: "/onboarding",
    element: <OnBoarding />,
    children: [
      {
        path: "create-profile",
        element: <CreateProfile />,
      },
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "setup",
        element: <Setup />,
      },
      {
        path: "team",
        element: <TeamOnBoarding />,
      }
    ]
  },
  {
    path: "/app",
    element: <Features />,
    children: [
      {
        path: "home",
        element: <Homepage />,
      },
      {
        path: ":project/:team/boards",
        element: <Boards />
      },
      {
        path: ":project/:team/boards/:id/board",
        element: <BoardView />,
      },
      {
        path: ":project/:team/boards/backlog",
        element: <Backlog />,
      }
    ]
  }
]);

export function App() {
  useInjectReducer({ key: appSlice, reducer: appReducer });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet titleTemplate="%s - Teambox" defaultTitle="Teambox">
        <meta name="description" content="Transform Your Software Development" />
      </Helmet>
      <RouterProvider router={router} />
    </Suspense>
  );
}
