import React from 'react';

interface BacklogIconProps {
    readonly color?: string;
    readonly size?: number;
}

export default function BacklogIcon({ color = "#79808A", size = 20 }: BacklogIconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/backlog">
                <path id="Vector" d="M17.5 2.32812H2.5V6.61384H17.5V2.32812ZM3.57143 5.54241V3.39955H16.4286V5.54241H3.57143ZM14.2857 11.971V7.68527H2.5V11.971H14.2857ZM13.2143 8.7567V10.8996H3.57143V8.7567H13.2143ZM17.5 17.3281V13.0424H2.5V17.3281H17.5ZM16.4286 14.1138V16.2567H3.57143V14.1138H16.4286Z" fill={color}/>
            </g>
        </svg>

    )
}