import React from 'react'

interface ReleaseIconProps {
    color?: string
    size?: number
    isCanceled?: boolean
}

export default function ReleaseIcon({ color="#79808A", size=16, isCanceled=false }: ReleaseIconProps) {
    return (
        <>
            {
                isCanceled ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                        <path d="M2 8L7.99363 11L14 8M2 11L7.99363 14L14 11M2 5.11775L7.99963 8L14 5.11775L7.99963 2L2 5.11775Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.5 14.5L13.5 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill="none">
                        <path d="M9.70038 2.15839L15.0861 5.00539C15.8856 5.42764 15.8856 6.57289 15.0861 6.99439L14.1306 7.49989L15.0861 8.00539C15.8856 8.42764 15.8856 9.57289 15.0861 9.99439L14.1306 10.4999L15.0861 11.0054C15.8856 11.4276 15.8856 12.5729 15.0861 12.9944L9.70038 15.8414C9.48429 15.9557 9.24356 16.0154 8.99912 16.0154C8.75469 16.0154 8.51395 15.9557 8.29787 15.8414L2.91212 12.9944C2.11263 12.5721 2.11263 11.4276 2.91212 11.0054L3.86837 10.4999L2.91212 9.99439C2.11263 9.57214 2.11263 8.42764 2.91212 8.00539L3.86837 7.49989L2.91212 6.99439C2.11263 6.57214 2.11263 5.42764 2.91212 5.00539L8.29787 2.15839C8.51395 2.04411 8.75469 1.98437 8.99912 1.98438C9.24356 1.98437 9.48429 2.04411 9.70038 2.15839ZM12.5256 11.3481L9.70038 12.8414C9.48429 12.9557 9.24356 13.0154 8.99912 13.0154C8.75469 13.0154 8.51395 12.9557 8.29787 12.8414L5.47263 11.3489L4.24038 11.9999L8.99912 14.5154L13.7586 11.9999L12.5256 11.3481ZM12.5256 8.34814L9.70038 9.84214C9.50444 9.94561 9.28801 10.0044 9.06664 10.0142C8.84528 10.0241 8.62448 9.98479 8.42012 9.89914L8.29787 9.84214L5.47263 8.34814L4.24038 9.00064L8.99912 11.5146L13.7586 8.99989L12.5256 8.34814ZM8.99912 3.48439L4.24038 5.99989L8.99912 8.51539L13.7586 5.99989L8.99912 3.48439Z" fill={color}/>
                    </svg>
                )
            }
        </>
    )
}           