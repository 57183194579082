import React from 'react'

interface PaneSwitchIconProps {
    color?: string
}

export default function PaneSwitchIcon({ color="#79808A" }: PaneSwitchIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <mask id="path-1-inside-1_18954_31957" fill="white">
                <path d="M2 2H18V18H2V2Z"/>
            </mask>
            <path d="M2 2H18V18H2V2Z" fill="white"/>
            <path d="M2 2V0.5H0.5V2H2ZM18 2H26V0.5H18V2ZM18 18V19.5H26V18H18ZM2 18H0.5V19.5H2V18ZM2 3.5H18V0.5H2V3.5ZM10 2V18H26V2H10ZM18 16.5H2V19.5H18V16.5ZM3.5 18V2H0.5V18H3.5Z" fill={color} mask="url(#path-1-inside-1_18954_31957)"/>
        </svg>
    )
}