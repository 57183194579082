import React from "react";

interface TicketsIconProps {
    color?: string
    size?: number
}

export default function TicketsIcon({
    color = '#79808A',
    size = 20
}:TicketsIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M13.2308 5.35243V5.90799M13.2308 8.13021V8.68576M13.2308 10.908V11.4635M13.2308 13.6858V14.2413M6.76923 10.3524H10.5385M6.76923 12.0191H8.92308M3.80769 4.79688C3.36185 4.79688 3 5.17021 3 5.63021V7.87169C3.32792 8.06649 3.60029 8.347 3.78968 8.68499C3.97908 9.02297 4.07879 9.40647 4.07879 9.79687C4.07879 10.1873 3.97908 10.5708 3.78968 10.9088C3.60029 11.2467 3.32792 11.5273 3 11.7221V13.9635C3 14.4235 3.36185 14.7969 3.80769 14.7969H16.1923C16.6382 14.7969 17 14.4235 17 13.9635V11.7221C16.6721 11.5273 16.3997 11.2467 16.2103 10.9088C16.0209 10.5708 15.9212 10.1873 15.9212 9.79687C15.9212 9.40647 16.0209 9.02297 16.2103 8.68499C16.3997 8.347 16.6721 8.06649 17 7.87169V5.63021C17 5.17021 16.6382 4.79688 16.1923 4.79688H3.80769Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}