import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { AuthorizedAccount, ContainerState, User } from "../types";
import { authorizedAccounts } from "app/containers/Auth/data";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    authorizedAccounts: authorizedAccounts,
    user: null,
};

const formSlice = createSlice({
  name: "AppState",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAuthorizedAccounts: (state, action: PayloadAction<AuthorizedAccount[]>) => {
      state.authorizedAccounts = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    }
  },    
});

export const { actions, reducer: appReducer, name: appSlice } = formSlice;
