import React from 'react'

interface CardProps {
    children: React.ReactNode
    className?: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

export default function Card({ children, className, onMouseEnter, onMouseLeave }: CardProps) {
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`bg-white border border-[#E9ECF2] rounded-[4px] px-[8px] pl-[16px] py-[12px] ${className}`}>
            {children}
        </div>
    )
}