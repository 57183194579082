import React from 'react'

export default function CalendarIcon({
    color="#79808A",
    size = 16
}: {
    readonly color?: string,
    readonly size?: number
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
            <g clip-path="url(#clip0_18954_225695)">
                <path d="M10.8889 2.55469H3.11111C2.49746 2.55469 2 3.05215 2 3.6658V11.4436C2 12.0572 2.49746 12.5547 3.11111 12.5547H10.8889C11.5025 12.5547 12 12.0572 12 11.4436V3.6658C12 3.05215 11.5025 2.55469 10.8889 2.55469Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.22656 1.44531V3.66753" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.77344 1.44531V3.66753" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 5.89062H12" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_18954_225695">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(0.335938 0.335938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}