import React from 'react'

interface CheckIconProps {
    size?: number
    color?: string
}

export default function CheckIcon({ size = 20, color = '#5A8DF1' }: CheckIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M7.49891 13.5015L4.58224 10.5848C4.50597 10.5076 4.41514 10.4464 4.315 10.4045C4.21487 10.3627 4.10743 10.3411 3.99891 10.3411C3.89039 10.3411 3.78295 10.3627 3.68281 10.4045C3.58268 10.4464 3.49184 10.5076 3.41557 10.5848C3.33837 10.6611 3.27708 10.7519 3.23525 10.8521C3.19342 10.9522 3.17188 11.0597 3.17188 11.1682C3.17188 11.2767 3.19342 11.3841 3.23525 11.4843C3.27708 11.5844 3.33837 11.6752 3.41557 11.7515L6.90724 15.2432C7.23224 15.5682 7.75724 15.5682 8.08224 15.2432L16.9156 6.41818C16.9928 6.34191 17.0541 6.25108 17.0959 6.15094C17.1377 6.05081 17.1593 5.94337 17.1593 5.83485C17.1593 5.72632 17.1377 5.61888 17.0959 5.51875C17.0541 5.41862 16.9928 5.32778 16.9156 5.25151C16.8393 5.17431 16.7485 5.11302 16.6483 5.07119C16.5482 5.02935 16.4408 5.00781 16.3322 5.00781C16.2237 5.00781 16.1163 5.02935 16.0161 5.07119C15.916 5.11302 15.8252 5.17431 15.7489 5.25151L7.49891 13.5015Z" fill={color} />
        </svg>
    )
}