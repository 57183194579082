import * as React from "react";
import { lazyLoad } from "utils/loadable";

export const OnBoarding = lazyLoad(
  () => import("./index"),
  (module) => module.OnBoardingPages,
  {
    fallback: <div>Loading...</div>,
  }
);
