import React from 'react'
import PaneSwitchIcon from "app/components/Icons/PaneSwitchIcon";
import Epic from './Epic';
import BacklogIcon from 'app/components/Icons/BacklogIcon';
import Filter from './Filter';
import Epics from '../containers/Epics';
import Components from '../containers/Components';
import Releases from '../containers/Releases';
import Stories from '../containers/Stories';
import Sprints from '../containers/Sprints';
import { PlanningTypes } from '../types';
import { useSearchParams } from 'react-router-dom';

export default function Planning() {
    const [searchParams] = useSearchParams()
    const planType = searchParams.get('plan-type') as PlanningTypes ?? PlanningTypes.EPICS
    return (
        <div className="w-[356px] flex-shrink-0 pl-[16px] border-l border-[#E9ECF2] flex flex-col h-full">
            <div className="flex flex-row items-center justify-between mb-[24px] flex-shrink-0">
                <p className="text-lg leading-[27px] font-medium">
                    Planning
                </p>
                <button className="size-[20px]">
                    <PaneSwitchIcon color="#253858" />
                </button>
            </div>
            <div className="flex-grow overflow-y-auto">
                <div className="flex flex-col gap-[10px]">
                    <Epic 
                        title="Backlog"
                        isActive
                        icon={<BacklogIcon size={16} color="#5A8DF1" />}
                        color="#5A8DF1"
                        story="12"
                        task="17"
                        bug="13"
                    />
                    <Filter />
                    {
                        planType === PlanningTypes.EPICS && (
                            <Epics />
                        )
                    }
                    {
                        planType === PlanningTypes.COMPONENTS && (
                            <Components />
                        )
                    }
                    {
                        planType === PlanningTypes.RELEASES && (
                            <Releases />
                        )
                    }
                    {
                        planType === PlanningTypes.STORIES && (
                            <Stories />
                        )
                    }
                    {
                        planType === PlanningTypes.SPRINT && (
                            <Sprints />
                        )
                    }
                </div>
            </div>
        </div>
    )
}