import React from 'react'

interface LogoProps {
    readonly size?: number
}

export default function Logo({ size = 24 }: LogoProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <rect x="7.98438" y="23.6797" width="4.4954" height="22.5217" rx="2.2477" transform="rotate(-134.632 7.98438 23.6797)" fill="#253858"/>
            <rect x="3.17188" y="19.1797" width="4.4954" height="22.5217" rx="2.2477" transform="rotate(-134.632 3.17188 19.1797)" fill="#253858"/>
            <rect x="0.185328" y="7.97757" width="4.2554" height="22.2817" rx="2.1277" transform="rotate(-44.6597 0.185328 7.97757)" fill="#FFCB00" stroke="white" strokeWidth="0.24"/>
            <rect x="4.68533" y="3.15726" width="4.2554" height="22.2817" rx="2.1277" transform="rotate(-44.6597 4.68533 3.15726)" fill="#5A8DF1" stroke="white" strokeWidth="0.24"/>
        </svg>
    )
}