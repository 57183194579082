import React from 'react'

export default function LabelIcon({
    size = 16,
    color="#79808A"
}: {
    readonly size?: number
    readonly color?: string
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M13.268 7.76324L8.22804 2.72324C8.01805 2.51496 7.7342 2.3982 7.43844 2.39844H3.51844C3.2214 2.39844 2.93652 2.51644 2.72648 2.72648C2.51644 2.93652 2.39844 3.2214 2.39844 3.51844V7.43844C2.39832 7.58623 2.42745 7.73258 2.48416 7.86906C2.54087 8.00554 2.62402 8.12945 2.72884 8.23364L7.76884 13.2736C7.97883 13.4819 8.26268 13.5987 8.55844 13.5984C8.855 13.5972 9.13896 13.4784 9.34804 13.268L13.268 9.34804C13.4784 9.13896 13.5972 8.855 13.5984 8.55844C13.5986 8.41065 13.5694 8.26429 13.5127 8.12782C13.456 7.99134 13.3729 7.86743 13.268 7.76324ZM8.55844 12.4784L3.51844 7.43844V3.51844H7.43844L12.4784 8.55844M4.91844 4.07844C5.08457 4.07844 5.24698 4.1277 5.38512 4.22C5.52325 4.3123 5.63092 4.44349 5.6945 4.59698C5.75807 4.75047 5.77471 4.91937 5.7423 5.08231C5.70989 5.24526 5.62988 5.39493 5.51241 5.51241C5.39493 5.62988 5.24526 5.70989 5.08231 5.7423C4.91937 5.77471 4.75047 5.75807 4.59698 5.6945C4.44349 5.63092 4.3123 5.52325 4.22 5.38512C4.1277 5.24698 4.07844 5.08457 4.07844 4.91844C4.07844 4.69566 4.16694 4.482 4.32447 4.32447C4.482 4.16694 4.69566 4.07844 4.91844 4.07844Z" fill={color} />
        </svg>
    )
}