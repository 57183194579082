import React from 'react'
import Home from './containers/Home'
import { useInjectReducer } from 'redux-injectors';
import { boardsReducer, boardsSlice } from './redux/slice';

export default function Boards() {
    useInjectReducer({ key: boardsSlice, reducer: boardsReducer });
    return (
        <Home />
    )
}
