import React from 'react'
import checkbox from '../../../assets/svgs/ic_checkbox_border.svg';
import checked from '../../../assets/svgs/ic_checked.svg';

interface MultiSelectProps {
    readonly options: {
        label: string;
        src: string;
    }[];
    readonly selected: string[];
    readonly onChange: (option: string | string[]) => void;
    readonly className?: string;
}

export default function MultiSelect({
    options,
    selected=[],
    onChange,
    className
}: MultiSelectProps) {
    const handleSelect = (option: string) => {
        if (selected.includes(option)) {
            onChange(selected?.filter((item) => item !== option));
        } else {
            onChange([...selected, option]);
        }
    }
    return (
        <div className={`flex flex-row items-center justify-center flex-wrap gap-y-[18px] gap-x-[16px] ${className}`}>
            {
                options.map((option) => (
                    <button onClick={() => handleSelect(option.label)} className={`cursor-pointer flex flex-row gap-[10px] h-[65px] items-center justify-center px-8 py-6 border border-gray-300 rounded-[4px] relative ${selected?.includes(option.label) ? 'border-primary bg-primary100' : ''}`} key={option.label}>
                        <img src={option.src} alt={option.label} width={42} height={42} />
                        <span className={`text-[24px] leading-[24px] font-medium text-center ${selected?.includes(option.label) ? 'text-primary' : 'text-secondary'}`}>{option.label}</span>
                        {
                            selected?.includes(option.label) ? (
                                <img src={checked} alt="Checked" width={32} height={32} />
                            ) : (
                                <img src={checkbox} alt="Checkbox" width={32} height={32} />
                            )
                        }
                    </button>
                ))
            }
        </div>
    )
}
