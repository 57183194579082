import React from 'react'
import { getTicketIcon } from '../containers/Home/containers/helpers'
import { TicketTypeButton } from './TicketTypeButton'
import { TicketStatusButton } from './TicketStatusButton'
import { AssigneeButton } from './AssigneeButton'
import { CalendarButton } from './CalendarButton'
import { PriorityButton } from './PriorityButton'
import { LabelsButton } from './LabelsButton'
import { TicketType } from '../types'

interface CreateTicketRowProps {
    readonly type: TicketType;
    readonly colSpan: number;
    readonly onCancel: () => void;
}

export default function CreateTicketRow({
    type,
    colSpan,
    onCancel
}: CreateTicketRowProps) {
    return (
        <tr className="h-[36px]">
            <td className="p-[4px]">
                <div className="flex flex-row items-center gap-[4px]">
                    <div className="min-w-[20px]">
                        <img src={getTicketIcon(type)} alt="task-icon" className="size-[20px]" />
                    </div>
                    <input 
                        type="text" 
                        className="w-full text-sm leading-[21px] font-normal focus:outline-none" 
                        placeholder="Enter task name" 
                    />
                </div>
            </td>
            <td className="p-[4px] flex flex-row items-center" colSpan={colSpan}>
                <div className="flex flex-row items-center gap-[10px]">
                    <div className="flex flex-row items-center gap-[6px]">
                        <TicketTypeButton type={type} />
                        <TicketStatusButton />
                        <AssigneeButton />
                        <CalendarButton />
                        <PriorityButton />
                        <LabelsButton />
                    </div>
                    <div className="flex flex-row items-center gap-[6px]">
                        <button 
                            className="flex flex-row items-center justify-center h-[28px] rounded-[4px] border border-[#E9ECF2] text-xs leading-[18px] font-medium text-secondary px-[10px]"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button className="flex flex-row items-center justify-center h-[28px] rounded-[4px] text-xs leading-[18px] font-medium text-white bg-primary px-[10px]">
                            Save
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    )
}
