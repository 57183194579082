import React from 'react';
import Card from '../Card';
import { PLAN, PLAN_TYPE } from 'app/containers/OnBoarding/types';

const MONTHLY_PRICES:PLAN[] = [
    {
        title: PLAN_TYPE.FREE,
        caption: 'The basics for individuals and organizations',
        price: 0,
        features: [
            '10GB Storage Per Project + 1 GB for successful referral',
            '3 Projects Maximum',
            'Activity Log 3 Month Back',
            'Business Hours email support',
            'Archive: 1 Month Back',
        ]
    },
    {
        title: PLAN_TYPE.BUSINESS,
        caption: 'The basics for individual sand organizations',
        price: 5,
        features: [
            'Unlimited Storage',
            'Unlimited Projects',
            'Unlimited Log',
            'Unlimited email support',
            'Unlimited Log',
        ]
    },
    {
        title: PLAN_TYPE.PROFESSIONAL,
        caption: 'The basics for individual sand organizations',
        price: 7,
        features: [
            'Unlimited Storage',
            'Unlimited Projects',
            'Unlimited Log',
            '24/7 Premium support',
            '24/7 Premium support',
        ]
    },
]

const Monthly = ({
    onClick
}:{
    onClick: (plan: PLAN_TYPE) => void;
}) => {
    return (
        <>
            {
                MONTHLY_PRICES.map((item) => (
                    <Card key={item?.title} item={item} onClick={onClick} />
                ))
            }
        </>
    );
}

export default Monthly;
