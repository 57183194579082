import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState } from "../types";
import { BOARDS } from "../data";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
    boards: BOARDS,
    favorites: ["1", "2", "3", "4", "5", "6"],
    qaBoards: ["1", "6"],
    recents: ["1", "2", "3"],
};

const formSlice = createSlice({
  name: "BoardsState",
  initialState,
  reducers: {
    setFavorites: (state, action: PayloadAction<string[]>) => {
        state.favorites = action.payload;
    },
    setQaBoards: (state, action: PayloadAction<string[]>) => {
        state.qaBoards = action.payload;
    },
    setRecents: (state, action: PayloadAction<string[]>) => {
        state.recents = action.payload;
    },
  },
});

export const { actions, reducer: boardsReducer, name: boardsSlice } = formSlice;
