import Modal from "app/components/Modal"
import React from "react"
import AddComponent from "./AddComponent"

interface AddComponentModalProps {
    onClose: () => void
}

export default function AddComponentModal({ onClose }: AddComponentModalProps) {
    return (
        <Modal
            onClose={onClose}
            submitText="Add"
            title="Add new component"
            contentClassName="!p-0"
            modalClassName="!w-[388px] !max-w-[388px]"
        >
            <AddComponent />
        </Modal>
    )
}