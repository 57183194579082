import Modal from "app/components/Modal"
import React from "react"

export default function Archive({
    onClose,
}: {
    readonly onClose: () => void
}) {
    return (
        <Modal
            onClose={onClose}
            title="Archive board"
        >
            <p className="text-secondary text-sm leading-[21px] font-medium">Are you sure that you want to archive QA bugs board?</p>
        </Modal>
    )
}