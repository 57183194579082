/**
 * Boards selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.AuthState || initialState;

export const selectLoginForm = createSelector(
    [selectDomain],
    (AuthState) => AuthState.loginForm,
);

export const selectSignupForm = createSelector(
    [selectDomain],
    (AuthState) => AuthState.signupForm,
);

export const selectForgotPasswordForm = createSelector(
    [selectDomain],
    (AuthState) => AuthState.forgotPasswordForm,
);

export const selectVerifyEmailForm = createSelector(
    [selectDomain],
    (AuthState) => AuthState.verifyEmailForm,
);

