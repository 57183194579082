import React, { useRef, useState } from 'react'
import google from '../../../../assets/svgs/ic_google.svg';
import Button from 'app/components/Button';
import { Link, useNavigate } from 'react-router-dom';
import rightArrow from '../../../../assets/svgs/ic_right_arrow_primary.svg';
import Input from 'app/components/Input';
import EmailIcon from 'app/components/Icons/EmailIcon';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { selectSignupForm } from '../redux/selector';
import { validateSignupForm } from '../helpers';
import Logo from 'app/components/Icons/Logo';

export default function Signup() {
    const [isEmail, setIsEmail] = useState(false);
    const dispatch = useDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const signupForm = useSelector(selectSignupForm);
    const navigate = useNavigate();

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const errors = validateSignupForm({
            email: {
                value: emailInputRef.current?.value || '',
                error: '',
            }
        });
        if (errors.length > 0) {
            dispatch(actions.setSignupFormErrors(errors));
            return;
        }
        dispatch(actions.signupRequest({email: emailInputRef.current?.value || '', callback: () => {
            navigate('/auth/verify-email');
        }}));
    }
    return (
        <div className="flex flex-col items-center">
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72}  />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Sign up to Teambox.Dev</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full max-w-[390px] mb-[56px]'>
                <Link to="/auth/verify-email">
                    <Button className='w-full h-[56px] flex items-center justify-center'>
                        <div className='flex items-center gap-[10px]'>
                            <img sizes='24px' src={google} alt="Google" />
                            <span>Sign up with Google</span>
                        </div>
                    </Button>
                </Link>
                {isEmail && (
                    <Input 
                        id="email" 
                        type="email" 
                        placeholder="Enter email address" 
                        icon={<EmailIcon />}
                        defaultValue={signupForm.email.value}
                        error={signupForm.email.error}
                        disabled={signupForm.isLoading}
                        inputRef={emailInputRef}
                        onChange={(e) => {
                            dispatch(actions.resetSignupFormError());
                        }}
                    />
                )}
                <Button 
                    type='button'
                    isLoading={signupForm.isLoading}
                    loaderClassName='border-secondary'
                    disabled={signupForm.isLoading || !!signupForm.email.error}
                    disabledClassName='opacity-50'
                    className='w-full h-[56px] !border !border-secondary bg-white text-secondary '
                    onClick={(e) => {
                        setIsEmail(true);
                        isEmail && handleSubmit(e);
                    }}
                >
                    Continue with email
                </Button>
            </form>
            <div className='flex flex-col items-center gap-[20px] max-w-[390px]'>
                <p className='text-secondary text-md leading-[24px] text-center'>By signing up, you agree to our <Link to="#" className='text-primary font-medium'>Terms of Service</Link> and <Link to="#" className='text-primary font-medium'>Data Processing Agreement</Link>.</p>
                <span>-</span>
                <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>Already have an account? <Link to="/auth/login" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Log in <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
            </div>
        </div>
    )
}
