import React, { useState } from 'react'
import menuIcon from 'assets/svgs/ic_menu-grey.svg'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import duplicateIcon from 'assets/svgs/ic_duplicate.svg'
import favoriteIcon from 'assets/svgs/ic_favorite.svg'
import archiveIcon from 'assets/svgs/ic_archive.svg'

export default function Options({
    onArchive,
}: {
    readonly onArchive: () => void
}) {
    const [open, setOpen] = useState(false)
    const options = [
        {
            label: 'Duplicate',
            onClick: () => {
                console.log('Edit')
            },
            icon: <img className="size-[20px]" src={duplicateIcon} alt="duplicate" />
        },
        {
            label: 'Add to favorites',
            onClick: () => {
                console.log('Delete')
            },
            icon: <img className="size-[15px]" src={favoriteIcon} alt="favorite" />
        },
        {
            label: 'Archive',
            onClick: onArchive,
            icon: <img className="size-[16px]" src={archiveIcon} alt="archive" />
        }
    ]

    return (
        <DropdownMenu
            open={open}
            onOpenChange={setOpen}
        >
            <DropdownMenuTrigger asChild>
                <button className={clsx("absolute left-[-42px] group-hover:opacity-100 opacity-0 hover:border hover:border-secondary hover:rounded-[1.5px]", {"opacity-100 opacity-0 border border-secondary rounded-[1.5px]" : open})}>
                    <img src={menuIcon} alt="menu" className="size-[24px]" />
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent 
                align="end" 
                className="py-[10px] min-w-[154px] z-10 !bg-white rounded-[4px] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] border border-[#E9ECF2] translate-x-[50px]" // move to the right by 50%
            >
                {
                    options.map((option) => (
                        <DropdownMenuItem 
                            className="cursor-pointer flex flex-row gap-[6px] items-center px-4 py-[12px] hover:outline-none text-sm leading-[21px] font-normal text-secondary hover:bg-[#F4F5F7]"
                            onClick={option.onClick}
                        >
                            {option.icon}
                            <span>{option.label}</span>
                        </DropdownMenuItem>
                    ))
                }
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
