import React from "react"

interface LetterCardProps {
    letter: string | React.ReactNode
    color?: string
    backgroundColor?: string
    className?: string
    letterClassName?: string
}

export default function LetterCard({ letter, color, backgroundColor, className, letterClassName }: LetterCardProps) {
    return (
        <div style={{ backgroundColor: backgroundColor }} className={`flex size-[24px] min-w-[24px] items-center justify-center rounded-[2px] ${className}`}>
            <span style={{ color: color }} className={`text-xs leading-[18px] font-medium ${letterClassName}`}>{letter}</span>
        </div>
    )
}