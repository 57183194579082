import React from 'react'

interface DropdownIconProps {
    color?: string
    size?: number
}

export default function DropdownIcon({ color="#79808A", size=24 }: DropdownIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M14.1693 7.4974L10.0026 11.6641L5.83594 7.4974" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}