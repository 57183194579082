import React, { useState, useRef, useEffect } from 'react'
import Button from '../Button'

type OTPProps = {
  onSubmit: (code: string) => void;
  error?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export default function OTP({onSubmit, error, disabled, isLoading}: OTPProps) {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return;
    
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = () => {
    const code = otp.join('');
    if (code.length === 6) {
      onSubmit(code);
    }
  };

  return (
    <div className='flex flex-col items-center gap-[20px]'>
        <div className='flex flex-row items-center gap-[16px]'>
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={el => inputRefs.current[index] = el as HTMLInputElement}
                className={`w-[51.67px] h-[56px] border rounded-[4px] focus:outline-none p-[15px] text-center ${error ? 'border-danger' : 'border-gray-100'}`}
                type="text"
                maxLength={1}
                value={digit}
                disabled={disabled || isLoading}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
            {error && <p className='text-[11px] leading-[18px] text-danger absolute bottom-[-18px]'>{error}</p>}
        </div>
        <Button 
          type='button'
          className='w-full h-[56px]'
          onClick={handleSubmit}
          disabled={otp.some(digit => digit === '' || disabled)}
          isLoading={isLoading}
        >
            Continue with login code
        </Button>
    </div>
  )
}
