import React from 'react'
import Layout from '../../components/Layout'
import Cards from './components/Cards'
import List from './components/List'

export default function Home() {
    return (
        <Layout>
            <div className="min-w-max">
                <Cards />
                <List />
            </div>
        </Layout>
    )
}
