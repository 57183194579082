import React from 'react'

interface ListIconProps {
    readonly color?: string;
    readonly size?: string;
}

export default function ListIcon({ color="#5A8DF1", size="20" }: ListIconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/list view">
                <g id="Vector">
                    <path d="M7.4974 5H16.6641H7.4974ZM7.4974 10H16.6641H7.4974ZM7.4974 15H16.6641H7.4974ZM4.16406 5V5.00833V5ZM4.16406 10V10.0083V10ZM4.16406 15V15.0083V15Z" fill={color}/>
                    <path d="M7.4974 5H16.6641M7.4974 10H16.6641M7.4974 15H16.6641M4.16406 5V5.00833M4.16406 10V10.0083M4.16406 15V15.0083" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>

    )
}