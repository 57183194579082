import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, PLAN_TYPE, TeamMember } from "../types";
import { FormError } from "app/types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
    profileForm: {
        name: {
            value: "",
            error: "",
        },
        password: {
            value: "",
            error: "",
        },
        confirmPassword: {
            value: "",
            error: "",
        },
        isLoading: false,
    },
    setupForm: {
        companySize: {
            value: "",
            error: "",
        },
        referalSource: {
            value: "",
            error: "",
        },
        usedTools: {
            value: [],
            error: "",
        },
        isLoading: false,
    },
    teamForm: {
        name: {
            value: "",
            error: "",
        },
        members: [
            {
                name: {
                    value: "",
                    error: "",
                },
                role: {
                    value: "",
                    error: "",
                },
            }
        ],
        plan: PLAN_TYPE.BUSINESS,
        isLoading: false
    }
};

const formSlice = createSlice({
  name: "OnBoardingState",
  initialState,
  reducers: {
    setProfileFormValue: (state, action: PayloadAction<{name: string, value: string}>) => { 
        state.profileForm[action.payload.name].value = action.payload.value;
        state.profileForm[action.payload.name].error = "";
    },
    setProfileFormErrors: (state, action: PayloadAction<FormError[]>) => {
        action.payload.forEach((error) => {
            state.profileForm[error.name].error = error.error;
        });
    },
    saveProfileRequest: (state, action: PayloadAction<{callback?: () => void}>) => {
        state.profileForm.isLoading = true;
    },
    saveProfileRequestComplete: (state) => {
        state.profileForm.isLoading = false;
    },
    setSetupFormValue: (state, action: PayloadAction<{name: string, value: string | string[] }>) => { 
        state.setupForm[action.payload.name].value = action.payload.value;
        state.setupForm[action.payload.name].error = "";
    },
    saveSetupRequest: (state, action: PayloadAction<{callback?: () => void}>) => {
        state.setupForm.isLoading = true;
    },
    saveSetupRequestComplete: (state) => {
        state.setupForm.isLoading = false;
    },
    setTeamFormValue: (state, action: PayloadAction<{name: string, value: string}>) => {
        state.teamForm[action.payload.name].value = action.payload.value;
        state.teamForm[action.payload.name].error = "";
    },
    setTeamFormErrors: (state, action: PayloadAction<FormError[]>) => {
        action.payload.forEach((error) => {
            state.teamForm[error.name].error = error.error;
        });
    },
    addTeamMember: (state) => {
        state.teamForm.members = [
            ...state.teamForm.members, 
            {
                name: {
                    value: "",
                    error: "",
                },
                role: {
                    value: "",
                    error: "",
                },
            }
        ];
    },
    updateTeamMember: (state, action: PayloadAction<{index: number, name: string, value: string}>) => {
        state.teamForm.members[action.payload.index][action.payload.name].value = action.payload.value;
        state.teamForm.members[action.payload.index][action.payload.name].error = "";
    },
    updateTeamMemberError: (state, action: PayloadAction<{index: number, name: string, error: string}>) => {
        state.teamForm.members[action.payload.index][action.payload.name].error = action.payload.error;
    },
    saveTeamRequest: (state, action: PayloadAction<{callback?: () => void}>) => {
        state.teamForm.isLoading = true;
    },
    saveTeamRequestComplete: (state) => {
        state.teamForm.isLoading = false;
    }
  },
});

export const { actions, reducer: onBoardingReducer, name: onBoardingSlice } = formSlice;
