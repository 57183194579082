import React from 'react'

interface KabanIconProps {
    color?: string
}

export default function KabanIcon({ color="#79808A"}: KabanIconProps) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/kanban">
                <rect id="Rectangle 39968" x="3.25" y="3.25" width="5.16667" height="13.5" rx="1.25" stroke={color} strokeWidth="1.5"/>
                <rect id="Rectangle 39969" x="11.5859" y="3.25" width="5.16667" height="5.16667" rx="1.25" stroke={color} strokeWidth="1.5"/>
                <rect id="Rectangle 39970" x="11.5859" y="11.5859" width="5.16667" height="5.16667" rx="1.25" stroke={color} strokeWidth="1.5"/>
            </g>
        </svg>


    )
}