import React from 'react';

interface HomeIconProps {
    readonly color?: string;
    readonly size?: number;
}

export default function HomeIcon({ color = '#79808A', size = 20 }: HomeIconProps) {
    return (
        <svg width={size}   height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/home">
                <path id="Vector" d="M7.80451 10.8586H7.70451V10.9586V16.4499H4.38832V8.04893L10.0026 3.81288L15.6169 8.04893V16.4499H12.3007V10.9586V10.8586H12.2007H7.80451ZM3.23594 17.5V17.6H3.33594H8.75689H8.85689V17.5V12.0087H11.1483V17.5V17.6H11.2483H16.6693H16.7693V17.5V7.52407V7.47421L16.7295 7.44421L10.0628 2.42014L10.0026 2.37478L9.94242 2.42014L3.27575 7.44421L3.23594 7.47421V7.52407V17.5Z" fill={color} stroke={color} strokeWidth="0.2"/>
            </g>
        </svg>
    )
}