import Card from 'app/components/Card'
import IconText from 'app/components/IconText'
import React, { useState } from 'react'
import { getTicketIcon } from '../../Home/containers/helpers'
import { TicketType } from '../../../types'

interface ReleaseProps {
    title: string
    icon: React.ReactNode
    color?: string
    story?: string
    task?: string
    bug?: string
    isActive?: boolean
    startDate?: string
    releaseDate?: string
}

export default function Release({ 
    title, 
    icon, 
    color, 
    story, 
    task, 
    bug, 
    isActive=false,
    startDate,
    releaseDate
}: ReleaseProps) {
    const [isHovered, setIsHovered] = useState(false)
    
    const clonedIcon = React.cloneElement(icon as React.ReactElement, { color: "#5A8DF1" });
    
    return (
        <Card 
            className={`cursor-pointer group flex flex-col gap-[10px] hover:border-[#5A8DF1] ${isActive ? '!border-[#5A8DF1]' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex flex-row justify-between items-center gap-[4px] h-[20px]">
                <IconText 
                    icon={isHovered || isActive ? clonedIcon : icon}
                    text={title}
                    className={`${isActive ? '!text-[#5A8DF1]' : ''} group-hover:!text-[#5A8DF1]`}
                    color={color}
                />

                <span className={`text-[10px] font-medium leading-[18px] h-[16px] px-[4px] rounded-[40px] ${releaseDate ? 'text-success bg-success100' : 'text-primary bg-primary100'}`}>
                    {releaseDate ? "Released" : "Unreleased"}
                </span>
            </div>
            <div className="flex flex-row justify-between items-center gap-[4px] h-[20px]">
                <p className="text-xs leading-[18px] text-secondary">Ticket types </p>
                <div className="flex flex-row items-center gap-[4px]">
                    <div className="flex flex-row items-center gap-[2px]">
                        <div className="min-w-[16px]">
                            <img src={getTicketIcon(TicketType.STORY)} className="size-[16px]" alt="story" />
                        </div>
                        <span className="text-xs leading-[18px] text-secondary mt-[2px]">{story}</span>
                    </div>
                    <div className="flex flex-row items-center gap-[2px]">
                        <div className="min-w-[16px]">
                            <img src={getTicketIcon(TicketType.TASK)} className="size-[16px]" alt="task" />
                        </div>
                        <span className="text-xs leading-[18px] text-secondary mt-[2px]">{task}</span>
                    </div>
                    <div className="flex flex-row items-center gap-[2px]">
                        <div className="min-w-[16px]">
                            <img src={getTicketIcon(TicketType.BUG)} className="size-[16px]" alt="bug" />
                        </div>
                        <span className="text-xs leading-[18px] text-secondary mt-[2px]">{bug}</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-between items-center gap-[4px] h-[20px]">
                <p className="text-xs leading-[18px] text-secondary">Start Date</p>
                <p className="text-xs leading-[18px] text-secondary">{startDate}</p>
            </div>
            <div className="flex flex-row justify-between items-center gap-[4px] h-[20px]">
                <p className="text-xs leading-[18px] text-secondary">Release Date</p>
                <p className="text-xs leading-[18px] text-secondary">{releaseDate||"-"}</p>
            </div>
        </Card>
    )
}