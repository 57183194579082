import React from "react"
import close from 'assets/svgs/ic_close.svg'

interface DialogProps {
    onClose: () => void
    onSubmit?: () => void
    submitText?: string
    title: string
    children: React.ReactNode
    contentClassName?: string
    modalClassName?: string
}

export default function Modal({ onClose, title="Title", children, contentClassName, modalClassName, onSubmit, submitText="Archive" }: DialogProps) {
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-[#0000004D] transition-opacity" aria-hidden="true"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className={`relative transform overflow-hidden border border-[#E9ECF2] rounded-[6px] bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${modalClassName}`}>
                        <div className="bg-[#F4F5F7] border-t border-[#E9ECF2] px-6 py-4 flex justify-between flex-row">
                            <h3 className="text-lg font-medium leading-[27px] text-black">{title}</h3>
                            <button onClick={onClose}>
                                <img src={close} alt="close" className="size-[24px]" />
                            </button>
                        </div>

                        <div className={`bg-white p-6 ${contentClassName}`}>
                            {children}
                        </div>
                        <div className="bg-[#F4F5F7] border-t border-[#E9ECF2] px-6 py-4 flex justify-end gap-4">
                            <button 
                                onClick={onClose}
                                type="button" 
                                className="bg-[transparent] text-secondary border border-secondary rounded-[4px] w-[106px] h-[36px] text-sm leading-[21px] font-normal text-center">
                                    Cancel
                            </button>
                            <button 
                                onClick={onClose}
                                type="button" 
                                className="bg-primary text-white rounded-[4px] w-[106px] h-[36px] text-sm leading-[21px] font-normal text-center">
                                {submitText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}