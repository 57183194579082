import React from 'react'
import TabLink from './TabLink'

const tabs = [
    {
        id: 1,
        title: 'Name your team',
    },
    {
        id: 2,
        title: 'Add members',
    },
    {
        id: 3,
        title: 'Select plan',
    }
]

interface TabNavigationProps {
    readonly activeIndex: number;
}

export default function TabNavigation({ activeIndex }: TabNavigationProps) {
    return (
        <div className="flex flex-row justify-between items-center border-b border-gray-100 pb-6 px-8 mb-6">
            {tabs.map((tab) => (
                <TabLink key={tab.id} tab={tab} completed={(tab.id < activeIndex) && (activeIndex !== tab.id)} active={activeIndex === tab.id} />
            ))}
        </div>
    )
}
