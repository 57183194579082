import React from 'react'
import Layout from '../../components/Layout'
import TicketGroup from '../../components/TicketGroup'
import BacklogIcon from 'app/components/Icons/BacklogIcon'
import { HEADERS, TICKETS } from './data'
import Row from './components/Row'
import Planning from './components/Planning'

export default function Backlog() {
    return (
        <Layout>
            <div className="ml-[38px] flex gap-[16px] h-[calc(100vh-200px)]">         
                <div className="flex-grow min-w-[600px]">
                    <TicketGroup 
                        icon={<BacklogIcon />}
                        title="Backlog"
                        count={TICKETS.length}
                        headers={HEADERS}
                        color="#79808A"
                    >
                        {
                            TICKETS.map((ticket) => (
                                <Row key={ticket.id} ticket={ticket} />
                            ))
                        }
                    </TicketGroup>
                </div>
                <Planning />
            </div>
        </Layout>
    )
}
