import React from 'react'

export default function FlagIcon({
    color="#79808A",
    size = 16
}: {
    readonly color?: string,
    readonly size?: number
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M3 1.60156V3.02378M3 3.02378H13L11.3333 5.86823L13 8.71267H3M3 3.02378V8.71267M3 14.4016V8.71267" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}