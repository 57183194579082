import Button from 'app/components/Button'
import Input from 'app/components/Input'
import { validateTeamFormName } from 'app/containers/OnBoarding/helpers';
import { selectTeamForm } from 'app/containers/OnBoarding/redux/selector';
import { actions } from 'app/containers/OnBoarding/redux/slice';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

interface FormOneProps {
    readonly onProceed: () => void;   
}

export default function FormOne({ onProceed }: FormOneProps) {
    const { name, isLoading } = useSelector(selectTeamForm);
    const dispatch = useDispatch();
    const handleSubmit = () => {
        const errors = validateTeamFormName(name);
        if (errors.length > 0) {
            dispatch(actions.setTeamFormErrors(errors));
            return;
        }
        onProceed();
    };
    return (
        <div className='flex flex-col items-center gap-[16px] mb-[56px]'>
            <Input 
                label="Enter team name" 
                id="team-name"
                containerClassName="w-[390px]"
                labelClassName="text-lg text-center text-black font-medium leading-[27px] mb-[10px]"
                value={name.value}
                onChange={(e) => dispatch(actions.setTeamFormValue({name: 'name', value: e.target.value}))}
                error={name.error}
            />
            <p className="text-sm text-center text-secondary font-normal leading-[21px]">After creating a team, you can invite others to join</p>
            <Button 
                className="w-[390px] h-[56px]"
                onClick={handleSubmit}
                disabled={isLoading || !name.value}
                disabledClassName="bg-secondary"
            >
                Create team
            </Button>
        </div>
    )
}
