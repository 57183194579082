import React from 'react'

interface StoryIconProps {
    color?: string
    size?: number
    isCanceled?: boolean
}

export default function StoryIcon({ color="#79808A", size=20, isCanceled=false }: StoryIconProps) {
    return (
        <>          
            {
                isCanceled ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_18954_253373)">
                            <path d="M11.9974 1.33594C12.351 1.33594 12.6902 1.47641 12.9402 1.72646C13.1903 1.97651 13.3307 2.31565 13.3307 2.66927V11.3293C13.3307 11.4413 13.3054 11.5439 13.2554 11.6439L12.8921 12.3706C12.8457 12.4632 12.8216 12.5654 12.8216 12.6689C12.8216 12.7725 12.8457 12.8747 12.8921 12.9673L13.2541 13.6926C13.3074 13.7942 13.3337 13.9078 13.3302 14.0225C13.3268 14.1372 13.2938 14.2491 13.2345 14.3473C13.1751 14.4455 13.0915 14.5268 12.9915 14.5832C12.8916 14.6396 12.7788 14.6692 12.6641 14.6693H4.66406C4.13363 14.6693 3.62492 14.4586 3.24985 14.0835C2.87478 13.7084 2.66406 13.1997 2.66406 12.6693V3.33594C2.66406 2.8055 2.87478 2.2968 3.24985 1.92172C3.62492 1.54665 4.13363 1.33594 4.66406 1.33594H11.9974ZM11.6027 12.0026H4.66406C4.49414 12.0028 4.33071 12.0679 4.20715 12.1845C4.08359 12.3011 4.00924 12.4606 3.99928 12.6302C3.98932 12.7998 4.04451 12.9669 4.15357 13.0972C4.26263 13.2275 4.41733 13.3112 4.58606 13.3313L4.66406 13.3359H11.6027C11.4676 12.9537 11.4518 12.5394 11.5574 12.1479L11.6027 12.0026ZM11.9974 2.66927H4.66406C4.50077 2.66929 4.34317 2.72924 4.22115 2.83775C4.09912 2.94625 4.02117 3.09577 4.00206 3.25794L3.9974 3.33594V10.7826C4.16406 10.7239 4.3414 10.6866 4.52473 10.6739L4.66406 10.6693H11.9974V2.66927ZM9.33073 4.66927C9.50065 4.66946 9.66408 4.73452 9.78764 4.85117C9.9112 4.96782 9.98555 5.12724 9.99551 5.29687C10.0055 5.4665 9.95028 5.63352 9.84122 5.76383C9.73216 5.89413 9.57746 5.97787 9.40873 5.99794L9.33073 6.0026H6.66406C6.49414 6.00242 6.33071 5.93735 6.20715 5.82071C6.08359 5.70406 6.00924 5.54464 5.99928 5.37501C5.98932 5.20538 6.04451 5.03835 6.15357 4.90805C6.26263 4.77775 6.41733 4.69401 6.58606 4.67394L6.66406 4.66927H9.33073Z" fill={color}/>
                            <path d="M1.5 14.5L13.5 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_18954_253373">
                            <rect width={size} height={size} fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                        <path d="M11.9974 1.33594C12.351 1.33594 12.6902 1.47641 12.9402 1.72646C13.1903 1.97651 13.3307 2.31565 13.3307 2.66927V11.3293C13.3307 11.4413 13.3054 11.5439 13.2554 11.6439L12.8921 12.3706C12.8457 12.4632 12.8216 12.5654 12.8216 12.6689C12.8216 12.7725 12.8457 12.8747 12.8921 12.9673L13.2541 13.6926C13.3074 13.7942 13.3337 13.9078 13.3302 14.0225C13.3268 14.1372 13.2938 14.2491 13.2345 14.3473C13.1751 14.4455 13.0915 14.5268 12.9915 14.5832C12.8916 14.6396 12.7788 14.6692 12.6641 14.6693H4.66406C4.13363 14.6693 3.62492 14.4586 3.24985 14.0835C2.87478 13.7084 2.66406 13.1997 2.66406 12.6693V3.33594C2.66406 2.8055 2.87478 2.2968 3.24985 1.92172C3.62492 1.54665 4.13363 1.33594 4.66406 1.33594H11.9974ZM11.6027 12.0026H4.66406C4.49414 12.0028 4.33071 12.0679 4.20715 12.1845C4.08359 12.3011 4.00924 12.4606 3.99928 12.6302C3.98932 12.7998 4.04451 12.9669 4.15357 13.0972C4.26263 13.2275 4.41733 13.3112 4.58606 13.3313L4.66406 13.3359H11.6027C11.4676 12.9537 11.4518 12.5394 11.5574 12.1479L11.6027 12.0026ZM11.9974 2.66927H4.66406C4.50077 2.66929 4.34317 2.72924 4.22115 2.83775C4.09912 2.94625 4.02117 3.09577 4.00206 3.25794L3.9974 3.33594V10.7826C4.16406 10.7239 4.3414 10.6866 4.52473 10.6739L4.66406 10.6693H11.9974V2.66927ZM9.33073 4.66927C9.50065 4.66946 9.66408 4.73452 9.78764 4.85117C9.9112 4.96782 9.98555 5.12724 9.99551 5.29687C10.0055 5.4665 9.95028 5.63352 9.84122 5.76383C9.73216 5.89413 9.57746 5.97787 9.40873 5.99794L9.33073 6.0026H6.66406C6.49414 6.00242 6.33071 5.93735 6.20715 5.82071C6.08359 5.70406 6.00924 5.54464 5.99928 5.37501C5.98932 5.20538 6.04451 5.03835 6.15357 4.90805C6.26263 4.77775 6.41733 4.69401 6.58606 4.67394L6.66406 4.66927H9.33073Z" fill={color}/>
                    </svg>
                )
            }
        </>
    )
}           