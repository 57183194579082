import React from 'react'
import Layout from '../../../../components/Layout'
import List from './containers/List'
import { useParams } from 'react-router-dom'
import Kaban from './containers/Kaban'

export default function BoardView() {
    const params = useParams()
    const id = params?.id
    return (
        <Layout>
            {
                id && (parseInt(id) > 2) ? 
                <Kaban /> : 
                <List />
            }
        </Layout>
    )
}
                        