import React from 'react'
import check from '../../../assets/svgs/ic_checkmark.svg';

interface SingleSelectProps {
    readonly options: {
        label: string;
        src: string;
    }[];
    readonly selected: string | string[];
    readonly onChange: (option: string | string[]) => void;
    readonly className?: string;
}

export default function SingleSelect({
    options,
    selected,
    onChange,
    className
}: SingleSelectProps) {
    return (
        <div className={`flex flex-row items-center justify-center flex-wrap gap-y-[18px] gap-x-[16px] ${className}`}>
            {
                options.map((option) => (
                    <button onClick={() => onChange(option.label)} className={`cursor-pointer h-[65px] flex flex-col items-center justify-center px-8 py-6 border border-gray-300 rounded-[4px] relative transition-all duration-300 ${selected === option.label ? 'border-primary bg-primary100' : ''}`} key={option.label}>
                        <span className={`text-[24px] leading-[24px] font-medium text-center ${selected === option.label ? 'text-primary' : 'text-secondary'}`}>{option.label}</span>
                        <div className={`absolute top-[-10px] right-[-10px] w-[28px] h-[28px] bg-primary rounded-[14px] flex items-center justify-center transition-all duration-300 ${selected === option.label ? 'opacity-100' : 'opacity-0'}`}>
                            <img src={check} alt="Check" width={24} height={24} />
                        </div>
                    </button>
                ))
            }
        </div>
    )
}
