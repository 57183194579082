/**
 * Boards selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.BoardsState || initialState;

export const selectBoards = createSelector(
    [selectDomain],
    (BoardsState) => BoardsState.boards,
);

export const selectFavorites = createSelector(
    [selectDomain],
    (BoardsState) => BoardsState.favorites,
);

export const selectQaBoards = createSelector(
    [selectDomain],
    (BoardsState) => BoardsState.qaBoards,
);

export const selectRecents = createSelector(
    [selectDomain],
    (BoardsState) => BoardsState.recents,
);