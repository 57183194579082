import { SprintStatus } from "./types"


export const getSprintStatusClasses = (status: SprintStatus) => {
    switch (status) {
        case SprintStatus.CURRENT:
            return 'bg-success100 text-success'
        case SprintStatus.PREVIOUS:
            return 'bg-danger100 text-danger'
        case SprintStatus.NEXT:
        return 'bg-purple100 text-purple'
        default:
        return ''
    }
}