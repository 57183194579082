import React from 'react';
import Button from 'app/components/Button';
import check from 'assets/svgs/ic_checker.svg';
import { PLAN, PLAN_TYPE } from 'app/containers/OnBoarding/types';
import { Link } from 'react-router-dom';

interface CardProps {
    item: PLAN;
    onClick: (plan: PLAN_TYPE) => void;
}

const Card = ({
    item,
    onClick
}:CardProps) => {
    return (
        <Link onClick={() => onClick(item?.title)} to={`#`}>
            <div className="flex flex-col gap-[14.13px] p-[18.85px] border border-[#CDCEE8] rounded-[5.89px] hover:border-primary hover:bg-primary100 transition-all duration-300 cursor-pointer group">
                <h3 className="text-[21.2px] leading-[27.09px] font-bold text-[#343E72]">${item?.price}<span className="text-[10.01px] leading-[9.68px] pl-[11px] text-[#2C345FB2]">/month</span></h3>
                <div className="flex flex-col gap-[12px]">
                    <h2 className="text-[16.49px] leading-[24.73px] font-black text-[#2C345F] capitalize">{item?.title}</h2>
                    <p className="text-[8.83px] leading-[8.54px] text-[#02134099] max-w-60">
                        {item?.caption}
                    </p>
                </div>
                <ul className="text-accent-foreground text-left h-full">
                    {
                        item?.features.map((feature, index) => (
                            <li key={index} className="flex items-center gap-[6px] mb-[9.42px] text-[8.83px] leading-[8.54px] text-[#02134099]">
                                <div style={{ width: '1.25rem' }}>
                                    <img
                                        src={check}
                                        alt="Check"
                                        width={12}
                                        height={12}
                                    />
                                </div>
                                <span style={{ width: '90%'}}>{feature}</span>
                            </li>
                        ))
                    }
                </ul>
                <div className="flex justify-center items-center">
                    <Button className="!text-[#2C345F] border-[0.59px] border-[#CDCEE8] text-[10.6px] h-[32px] w-[190px] bg-white !rounded-[14.13px] font-medium group-hover:bg-primary group-hover:text-white transition-colors duration-300">
                        <span className="group-hover:text-white transition-colors duration-300">Choose Plan</span>
                    </Button>
                </div>
            </div>
        </Link>
    );
}

export default Card;
