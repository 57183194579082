import * as React from "react";
import { lazyLoad } from "utils/loadable";

export const Auth = lazyLoad(
  () => import("./index"),
  (module) => module.AuthPages,
  {
    fallback: <div>Loading...</div>,
  }
);
