import React from 'react';
import Filters from '../components/Filters';
import Cards from '../components/Cards';
import { PLAN_TYPE } from 'app/containers/OnBoarding/types';

interface Props {
    onProceed: (plan: PLAN_TYPE) => void;
}

const Pricing = ({
    onProceed,
}:Props) => {
    return (
        <div className="flex flex-col gap-[16px] mb-[56px]">
            <Filters />
            <Cards onClick={onProceed} />
        </div>
    );
}

export default Pricing;
