import React from 'react'

interface ComponentIconProps {
    color?: string
    size?: number
    isCanceled?: boolean
}

export default function ComponentIcon({ color="#79808A", size=20, isCanceled=false }: ComponentIconProps) {
    return (
        <>
            {
                isCanceled ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                        <rect width="16" height="16" fill="white"/>
                        <path d="M11.0743 7.17688V2.66406M8.81791 4.92047H13.3307M6.35637 2.66406H3.48458C3.26696 2.66406 3.05826 2.75051 2.90439 2.90439C2.75051 3.05826 2.66406 3.26696 2.66406 3.48458V6.35637C2.66406 6.57398 2.75051 6.78268 2.90439 6.93656C3.05826 7.09044 3.26696 7.17688 3.48458 7.17688H6.35637C6.57398 7.17688 6.78268 7.09044 6.93656 6.93656C7.09044 6.78268 7.17688 6.57398 7.17688 6.35637V3.48458C7.17688 3.26696 7.09044 3.05826 6.93656 2.90439C6.78268 2.75051 6.57398 2.66406 6.35637 2.66406ZM12.5102 8.81791H9.63842C9.42081 8.81791 9.21211 8.90436 9.05823 9.05823C8.90436 9.21211 8.81791 9.42081 8.81791 9.63842V12.5102C8.81791 12.7278 8.90436 12.9365 9.05823 13.0904C9.21211 13.2443 9.42081 13.3307 9.63842 13.3307H12.5102C12.7278 13.3307 12.9365 13.2443 13.0904 13.0904C13.2443 12.9365 13.3307 12.7278 13.3307 12.5102V9.63842C13.3307 9.42081 13.2443 9.21211 13.0904 9.05823C12.9365 8.90436 12.7278 8.81791 12.5102 8.81791ZM6.35637 8.81791H3.48458C3.26696 8.81791 3.05826 8.90436 2.90439 9.05823C2.75051 9.21211 2.66406 9.42081 2.66406 9.63842V12.5102C2.66406 12.7278 2.75051 12.9365 2.90439 13.0904C3.05826 13.2443 3.26696 13.3307 3.48458 13.3307H6.35637C6.57398 13.3307 6.78268 13.2443 6.93656 13.0904C7.09044 12.9365 7.17688 12.7278 7.17688 12.5102V9.63842C7.17688 9.42081 7.09044 9.21211 6.93656 9.05823C6.78268 8.90436 6.57398 8.81791 6.35637 8.81791Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.5 14.5L13.5 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                        <rect width="16" height="16" fill="white"/>
                        <path d="M11.0743 7.17688V2.66406M8.81791 4.92047H13.3307M6.35637 2.66406H3.48458C3.26696 2.66406 3.05826 2.75051 2.90439 2.90439C2.75051 3.05826 2.66406 3.26696 2.66406 3.48458V6.35637C2.66406 6.57398 2.75051 6.78268 2.90439 6.93656C3.05826 7.09044 3.26696 7.17688 3.48458 7.17688H6.35637C6.57398 7.17688 6.78268 7.09044 6.93656 6.93656C7.09044 6.78268 7.17688 6.57398 7.17688 6.35637V3.48458C7.17688 3.26696 7.09044 3.05826 6.93656 2.90439C6.78268 2.75051 6.57398 2.66406 6.35637 2.66406ZM12.5102 8.81791H9.63842C9.42081 8.81791 9.21211 8.90436 9.05823 9.05823C8.90436 9.21211 8.81791 9.42081 8.81791 9.63842V12.5102C8.81791 12.7278 8.90436 12.9365 9.05823 13.0904C9.21211 13.2443 9.42081 13.3307 9.63842 13.3307H12.5102C12.7278 13.3307 12.9365 13.2443 13.0904 13.0904C13.2443 12.9365 13.3307 12.7278 13.3307 12.5102V9.63842C13.3307 9.42081 13.2443 9.21211 13.0904 9.05823C12.9365 8.90436 12.7278 8.81791 12.5102 8.81791ZM6.35637 8.81791H3.48458C3.26696 8.81791 3.05826 8.90436 2.90439 9.05823C2.75051 9.21211 2.66406 9.42081 2.66406 9.63842V12.5102C2.66406 12.7278 2.75051 12.9365 2.90439 13.0904C3.05826 13.2443 3.26696 13.3307 3.48458 13.3307H6.35637C6.57398 13.3307 6.78268 13.2443 6.93656 13.0904C7.09044 12.9365 7.17688 12.7278 7.17688 12.5102V9.63842C7.17688 9.42081 7.09044 9.21211 6.93656 9.05823C6.78268 8.90436 6.57398 8.81791 6.35637 8.81791Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                )
            }
        </>
    )
}