import React, { useState } from 'react'
import Epic from '../components/Epic'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import ReleaseIcon from 'app/components/Icons/ReleaseIcon'
import ToggleButton from '../../../components/ToggleButton'
import Release from '../components/Release'
import AddReleaseModal from '../../../components/AddReleaseModal'

const RELEASES = [
    {
        title: {
            name: "1.0",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',
        startDate: '20 Jan, 2024',
        releaseDate: '14 Feb, 2024',
    },
    {
        title: {
            name: "2.0",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',
        startDate: '14 Feb, 2024',
        releaseDate: '',
    },
    {
        title: {
            name: "3.0",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',
    }
]

export default function Releases() {
    const [isAddReleaseModalOpen, setIsAddReleaseModalOpen] = useState(false)
    return (
        <div className="flex flex-col gap-[10px]">
            <Epic 
                icon={<ReleaseIcon isCanceled={true} color="#253858" />}
                title="Tickets without release"
                story="-"
                task="5"
                bug="2"
                color="#253858"
            />
            <ToggleButton
                isActive={false}
                onClick={() => {}}
            />
            {RELEASES.map((release, index) => (
                <Release 
                    icon={<ReleaseIcon color={release.title.color} />}
                    key={index} 
                    title={release.title.name} 
                    story={release.story} 
                    task={release.task} 
                    bug={release.bug} 
                    color={release.title.color}
                    startDate={release.startDate}
                    releaseDate={release.releaseDate}
                />
            ))}
            <button className="flex flex-row w-fit items-center gap-[4px]" onClick={() => setIsAddReleaseModalOpen(true)}>
                <img src={addIcon} alt="add" className="size-[20px]" />
                <span className="text-sm leading-[24px] text-primary font-semibold mt-[2px] font-sans">Release</span>
            </button>
            {
                isAddReleaseModalOpen && (
                    <AddReleaseModal onClose={() => setIsAddReleaseModalOpen(false)} />
                )
            }
        </div>
    )
}