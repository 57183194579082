import React, { useState } from 'react'
import Epic from '../components/Epic'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import ToggleButton from '../../../components/ToggleButton'
import Sprint from '../components/Sprint'
import SprintIcon from 'app/components/Icons/SprintIcon'
import { SprintStatus } from '../../../types'
import AddSprintModal from '../../../components/AddSprintModal'

const SPRINTS = [
    {
        title: {
            name: "Bugbusters",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',
        duration: '14 Feb, 2024 - 27 Feb, 2024',
        hours: 90,
        totalHours: 90,
        status: SprintStatus.PREVIOUS
    },
    {
        title: {
            name: "Testing Titans",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',        
        duration: '14 Feb, 2024 - 27 Feb, 2024',
        hours: 10,
        totalHours: 90,
        status: SprintStatus.CURRENT
    },
    {
        title: {
            name: "Operation Warp Speed sprint",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',        
        duration: '14 Feb, 2024 - 27 Feb, 2024',
        hours: 0,
        totalHours: 27,
        status: SprintStatus.NEXT
    },
    {
        title: {
            name: "Code Crusaders",
            color: "#253858"
        },
        story: '1',
        task: '1',
        bug: '1',        
        duration: '28 Feb, 2024 - 12 Mar, 2024',
        hours: 87,
        totalHours: 90,
        status: SprintStatus.NEXT
    },
]

export default function Sprints() {
    const [isAddSprintModalOpen, setIsAddSprintModalOpen] = useState(false)
    return (
        <div className="flex flex-col gap-[10px]">
            <Epic 
                icon={<SprintIcon isCanceled={true} color="#253858" />}
                title="Tickets without sprint "
                story="-"
                task="5"
                bug="2"
                color="#253858"
            />
            <ToggleButton
                isActive={false}
                onClick={() => {}}
                label="Show previous sprints"
            />
            {SPRINTS.map((release, index) => (
                <Sprint 
                    icon={<SprintIcon color={release.title.color} />}
                    key={index} 
                    title={release.title.name} 
                    story={release.story} 
                    task={release.task} 
                    bug={release.bug} 
                    color={release.title.color}
                    duration={release.duration}
                    hours={release.hours}
                    totalHours={release.totalHours}
                    status={release.status}
                />
            ))}
            <button className="flex flex-row w-fit items-center gap-[4px]" onClick={() => setIsAddSprintModalOpen(true)}>
                <img src={addIcon} alt="add" className="size-[20px]" />
                <span className="text-sm leading-[24px] text-primary font-semibold mt-[2px] font-sans">Sprint</span>
            </button>
            {
                isAddSprintModalOpen && (
                    <AddSprintModal onClose={() => setIsAddSprintModalOpen(false)} />
                )
            }
        </div>
    )
}