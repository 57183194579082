import React from 'react'

interface EpicIconProps {
    color?: string
    size?: number
    isCanceled?: boolean
}

export default function EpicIcon({ color="#79808A", size=20, isCanceled=false }: EpicIconProps) {
    return (
        <>
            {
                isCanceled ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.58702 8.42857L4.6701 3.28571H11.336L13.4191 8.42857H2.58702ZM1.33717 8.48771V10.3571C1.33717 11.1851 1.99042 11.8571 2.79533 11.8571H3.00364V12.5C3.00364 13.328 3.65689 14 4.4618 14H11.5443C11.71 14 11.869 13.9323 11.9862 13.8117C12.1034 13.6912 12.1692 13.5276 12.1692 13.3571C12.1692 13.1866 12.1034 13.0231 11.9862 12.9026C11.869 12.782 11.71 12.7143 11.5443 12.7143H4.4618C4.40655 12.7143 4.35357 12.6917 4.3145 12.6515C4.27543 12.6113 4.25349 12.5568 4.25349 12.5V11.8571H12.7941C12.9599 11.8571 13.1188 11.7894 13.236 11.6689C13.3532 11.5483 13.4191 11.3848 13.4191 11.2143C13.4191 11.0438 13.3532 10.8803 13.236 10.7597C13.1188 10.6392 12.9599 10.5714 12.7941 10.5714H2.79533C2.74008 10.5714 2.6871 10.5489 2.64803 10.5087C2.60896 10.4685 2.58702 10.414 2.58702 10.3571V9.71429H13.4191C13.6249 9.71434 13.8275 9.66213 14.009 9.56227C14.1904 9.46242 14.3451 9.31801 14.4593 9.14188C14.5735 8.96574 14.6436 8.76333 14.6634 8.55262C14.6833 8.34191 14.6523 8.12941 14.5731 7.934L12.3825 2.52714C12.3192 2.37101 12.2124 2.23764 12.0755 2.14384C11.9387 2.05003 11.7779 1.99999 11.6135 2H4.39264C4.22818 1.99999 4.0674 2.05003 3.93055 2.14384C3.79369 2.23764 3.68688 2.37101 3.62356 2.52714L1.43299 7.934C1.36171 8.10904 1.32899 8.29813 1.33717 8.48771Z" fill={color}/>
                        <path d="M1.5 14.5L13.5 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.22791 10.5357L5.83177 4.10714H14.1641L16.768 10.5357H3.22791ZM1.6656 10.6096V12.9464C1.6656 13.9814 2.48217 14.8214 3.4883 14.8214H3.74868V15.625C3.74868 16.66 4.56526 17.5 5.57139 17.5H14.4245C14.6317 17.5 14.8304 17.4153 14.9769 17.2646C15.1234 17.1139 15.2057 16.9095 15.2057 16.6964C15.2057 16.4833 15.1234 16.2789 14.9769 16.1282C14.8304 15.9775 14.6317 15.8929 14.4245 15.8929H5.57139C5.50233 15.8929 5.4361 15.8646 5.38727 15.8144C5.33843 15.7642 5.311 15.696 5.311 15.625V14.8214H15.9868C16.194 14.8214 16.3927 14.7368 16.5392 14.5861C16.6857 14.4354 16.768 14.231 16.768 14.0179C16.768 13.8047 16.6857 13.6003 16.5392 13.4496C16.3927 13.2989 16.194 13.2143 15.9868 13.2143H3.4883C3.41924 13.2143 3.35301 13.1861 3.30418 13.1358C3.25535 13.0856 3.22791 13.0175 3.22791 12.9464V12.1429H16.768C17.0252 12.1429 17.2785 12.0777 17.5053 11.9528C17.7322 11.828 17.9255 11.6475 18.0682 11.4273C18.211 11.2072 18.2986 10.9542 18.3234 10.6908C18.3483 10.4274 18.3095 10.1618 18.2105 9.9175L15.4723 3.15893C15.3932 2.96376 15.2596 2.79705 15.0886 2.6798C14.9175 2.56254 14.7165 2.49998 14.511 2.5H5.48494C5.27937 2.49998 5.07839 2.56254 4.90732 2.6798C4.73625 2.79705 4.60274 2.96376 4.52359 3.15893L1.78537 9.9175C1.69628 10.1363 1.65538 10.3727 1.6656 10.6096Z" fill={color}/>
                    </svg> 
                )
            }
        </>
    )
}