import React, { useState, useRef, useEffect } from 'react';
import DropdownIcon from '../Icons/DropdownIcon';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { Roles } from 'app/containers/OnBoarding/data';

interface SelectProps<T> {
    options: T;
    value: string;
    error?: string;
    label?: string;
    id?: string;
    containerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    activeColor?: string;
    icon?: React.ReactNode;
    placeholder?: string;
    onChange?: (value: string) => void;
    innerContainerClassName?: string;
}

export const Select: React.FC<SelectProps<typeof Roles>> = ({
    options, 
    value, 
    error,
    label, 
    id, 
    containerClassName, 
    inputClassName, 
    labelClassName, 
    activeColor, 
    icon, 
    placeholder,
    onChange,
    innerContainerClassName
}) => {
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [dropdownWidth, setDropdownWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setDropdownWidth(containerRef.current.offsetWidth);
        }
    }, []);

    const clonedIcon = icon && React.cloneElement(icon as React.ReactElement, { color: error ? "#EB5757" : open ? activeColor : "#BDC0C3" });

    return (
        <div ref={containerRef} className={`relative ${containerClassName}`}>
            <DropdownMenu open={open} onOpenChange={setOpen}>
                <div className={`flex flex-col gap-[6px] ${innerContainerClassName}`}>
                    {
                        label &&
                        <label htmlFor={id} className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}>{label}</label>
                    }
                    <DropdownMenuTrigger asChild>
                        <div className={`flex items-center gap-[8px] flex-row border rounded-[4px] py-[16px] px-[10px] h-[48px] ${inputClassName} ${open ? 'border-[#79808A]' : 'border-[#E9ECF2]'} ${error && 'border-danger'}`}>
                            {
                                icon &&
                                <div>
                                    {clonedIcon}
                                </div>
                            }
                            <button 
                                className="flex items-center justify-between text-sm rounded w-full text-gray-700 leading-[21px] focus:outline-none placeholder:text-[#BDC0C3] placeholder:font-normal placeholder:text-sm placeholder:leading-[21px]" 
                            >
                                {
                                    value ?
                                    <span className="font-normal text-sm leading-[21px] text-black">
                                        {options.find((option) => option.value === value)?.label}
                                    </span>
                                    :
                                    <span className="font-normal text-sm leading-[21px] text-[#BDC0C3]">
                                        {placeholder}
                                    </span>
                                }
                                <div className={`size-[16px] min-w-[16px] transition-transform duration-300 ease-in-out ${open ? 'rotate-180' : ''}`}>
                                    <DropdownIcon size={16} />
                                </div>
                            </button>
                        </div>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent
                        className="py-[10px] bg-danger z-10 !bg-white rounded-[4px] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] border border-[#E9ECF2] max-h-[320px] overflow-y-auto"
                        style={{ width: `${dropdownWidth}px` }}
                        sideOffset={5}
                    >
                        {
                            options.map((option) => (
                                <DropdownMenuItem 
                                    key={option.value} 
                                    onClick={() => {
                                        onChange?.(option.value);
                                        setOpen(false);
                                    }}
                                    className="cursor-pointer hover:outline-none text-sm leading-[21px] font-normal text-secondary hover:bg-[#F4F5F7] px-[8px] pl-[16px] py-[10px] flex flex-col gap-[8px]"
                                >
                                    <span className='text-sm leading-[21px] font-normal text-black'>{option.label}</span>
                                    <span className='text-xs leading-[16px] font-normal text-[#79808A]'>{option.description}</span>
                                </DropdownMenuItem>
                            ))
                        }
                    </DropdownMenuContent>
                </div>
                {error && (
                    <p className='text-[11px] leading-[18px] text-danger absolute bottom-[-18px]'>{error}</p>
                )}
            </DropdownMenu>
        </div>
    );
}
