import Table from 'app/components/Table'
import { TableHeader } from 'app/components/Table/types';
import React, { useState } from 'react'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import menuIcon from "assets/svgs/ic_menu-grey.svg"
import DropdownIcon from 'app/components/Icons/DropdownIcon';
import { TicketType } from '../types';
import CreateTicketRow from './CreateTicketRow';

interface TicketGroupProps {
    icon: React.ReactNode;
    title: string;
    count: number;
    children: React.ReactNode;
    titleClassName?: string;
    headers: TableHeader[];
    color: string;
}

export default function TicketGroup({ icon, title, count, children, titleClassName, headers, color }: TicketGroupProps) {
    const [showAddTask, setShowAddTask] = useState<{
        show: boolean;
        type: TicketType;
    }>({
        show: false,
        type: TicketType.TASK
    });
    return (
        <div className="w-[100%]">
            <div className="flex flex-row items-center gap-[6px] h-[30px]">
                <div className="flex items-center justify-center">
                    {icon}
                </div>
                <p style={{ color: color }} className={`font-medium text-sm leading-[21px] mb-0 mt-[2px] ${titleClassName}`}>
                    {title}
                </p>
                <div className="flex items-center justify-center size-[20px] border border-[#E9ECF2] rounded-full text-sm leading-[12px] text-secondary font-normal">
                    {count}
                </div>
                <button className="">
                    <img src={menuIcon} alt="edit" className="size-[20px]" />
                </button>
            </div>
            <Table 
                headers={headers} 
                headerClassName="!h-[30px] border-b border-[#E9ECF2]" 
                tableClassName="border-none" 
            >
                {children}
                {
                    showAddTask.show && (
                        <CreateTicketRow 
                            type={showAddTask.type} 
                            colSpan={headers.length - 1} 
                            onCancel={() => setShowAddTask({
                                show: false,
                                type: TicketType.TASK
                            })}
                        />
                    )
                }
            </Table>
            <button 
                onClick={() => setShowAddTask({
                    show: true,
                    type: TicketType.TASK
                })} 
                className="flex flex-row w-fit items-center gap-[4px] mt-[5px] mb-[15px] group"
            >
                <div className="flex justify-center items-center size-[20px]">
                    <img src={addIcon} alt="add" className="size-[20px]" />
                </div>
                <div className="flex flex-row items-center group-hover:bg-white rounded-[4px]">
                    <button className="hover:bg-[#F4F5F7] rounded-l-[4px] h-[20px] px-[6px] py-auto pb-2 text-sm leading-[21px] font-normal text-primary">
                        Add task
                    </button>
                    <button className="hidden group-hover:flex hover:bg-[#F4F5F7] bg-white rounded-r-[4px] border-l-[1px] border-[#E9ECF2] justify-center items-center size-[20px]">
                        <DropdownIcon size={20} />
                    </button>
                </div>
            </button>
        </div>
    )
}
