import React from 'react'

interface IconTextProps {
    icon: React.ReactNode
    text: string
    className?: string
    color?: string
    textClassName?: string
    onIconClick?: () => void
    iconClassName?: string
}

export default function IconText({ icon, text, color, className = '', textClassName = '', onIconClick, iconClassName }: IconTextProps) {
    return (
        <div style={{ color: color }} className={`flex items-center gap-1 ${className}`}>
            <button onClick={onIconClick} className={`focus:outline-none ${iconClassName}`}>
                {icon}
            </button>
            <span className={`text-nowrap overflow-hidden text-ellipsis ${textClassName}`}>{text}</span>
        </div>
    )
}