import React from 'react'
import { TICKET_HEADERS, TICKET_GROUPS } from 'app/containers/Features/containers/Boards/data'
import StatusIcon from 'app/components/Icons/StatusIcon'
import TicketGroup from 'app/containers/Features/containers/Boards/components/TicketGroup'
import Row from '../../../components/Row'

export default function List() {
    return (
        <div className="pl-[38px]">         
            {
                TICKET_GROUPS.map((group) => (
                    <TicketGroup 
                        key={group.status.name}
                        icon={<StatusIcon color={group.status.color} />}
                        title={group.status.name}
                        count={group.tickets.length}
                        headers={TICKET_HEADERS}
                        color={group.status.color}
                    >
                        {
                            group.tickets.map((ticket) => (
                                <Row key={ticket.id} ticket={ticket} />
                            ))
                        }
                    </TicketGroup>
                ))
            }
        </div>
    )
}