import Input from "app/components/Input"
import Textarea from "app/components/Icons/Textarea"
import { Select } from "app/components/Select"
import React from "react"

export default function AddComponent() {
    return (
        <div className="flex flex-col px-[24px] py-4 pt-[12px] gap-4">
            <div className="flex flex-col gap-y-4">
                <Input
                    label="Component name"
                    placeholder="Enter component name..."
                    id='component-name'
                    inputClassName='!h-[48px] !gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                />
                <Select
                    label="Component lead"
                    placeholder="Select component lead..."
                    id='component-lead'
                    inputClassName='!h-[48px] !gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                    options={[]}
                    value=""
                />
                <Select
                    label="Default assignee"
                    placeholder="Select component lead..."
                    id='component-lead'
                    inputClassName='!h-[48px] !gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                    options={[]}
                    value=""
                />
                <Textarea
                    label="Component description"
                    placeholder="Enter Component description"
                    id='component-description'
                    inputClassName='!gap-[0px]'
                    innerContainerClassName="!gap-[0px]"
                />
            </div>
        </div>
    )
}