import React, { useRef } from 'react'
import Input from 'app/components/Input';
import { Link } from 'react-router-dom';
import Button from 'app/components/Button';
import rightArrow from 'assets/svgs/ic_right_arrow_primary.svg';
import { selectLoginForm } from '../redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { validateLoginForm } from '../helpers';
import { LoginForm } from '../types';
import EmailIcon from 'app/components/Icons/EmailIcon';
import PasswordIcon from 'app/components/Icons/PasswordIcon';
import Logo from 'app/components/Icons/Logo';

export default function Login() {
    const dispatch = useDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const loginForm = useSelector(selectLoginForm);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const data: Omit<LoginForm, 'isLoading'> = {
            email: {
                value: emailInputRef.current?.value || '',
                error: '',
            },
            password: {
                value: passwordInputRef.current?.value || '',
                error: '',
            },
        }
        const errors = validateLoginForm(data);
        if (errors.length > 0) {
            dispatch(actions.setLoginFormErrors(errors));
            return;
        }
        dispatch(actions.loginRequest({
            email: data.email.value,
            password: data.password.value
        }));
    }
    
    return (
        <div className="flex flex-col items-center">
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72} />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Welcome back</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full min-w-[390px] mb-[56px]'>
                <Input 
                    id="email" 
                    label="Email" 
                    type="email" 
                    placeholder="Enter email address" 
                    icon={<EmailIcon />}
                    inputRef={emailInputRef}
                    defaultValue={loginForm.email.value}
                    error={loginForm.email.error}
                    disabled={loginForm.isLoading}
                    onChange={(e) => {
                        dispatch(actions.resetLoginFormError('email'));
                    }}
                />
                <div>
                <Input 
                    id="password" 
                    label="Password" 
                    type="password" 
                    placeholder="Password" 
                    icon={<PasswordIcon />}
                    inputRef={passwordInputRef}
                    defaultValue={loginForm.password.value}
                    error={loginForm.password.error}
                    disabled={loginForm.isLoading}
                    onChange={(e) => {
                        dispatch(actions.resetLoginFormError('password'));
                    }}
                />
                <div className='flex justify-end mt-[6px]'>
                    <Link to="/auth/forgot-password" className="text-right text-sm text-primary">Forgot password?</Link>
                </div>
                </div>
                <div className="flex items-center justify-between">
                    <Button 
                        disabled={!!loginForm.email.error || !!loginForm.password.error}
                        onClick={handleSubmit} 
                        isLoading={loginForm.isLoading}
                        className='w-full h-[56px]'
                    >
                        Log in
                    </Button>
                </div>
            </form>
            <div className='flex flex-col items-center gap-[16px]'>
                <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>Don’t have an account? <Link to="/auth/signup" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Sign up <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
            </div>
        </div>
    )
}
