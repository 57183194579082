import React, { useState } from 'react'
import TabNavigation from './components/TabNavigation';
import FormOne from './containers/FormOne';
import FormTwo from './containers/FormTwo';
import Pricing from './containers/Pricing';
import { actions } from 'app/containers/OnBoarding/redux/slice';
import { useDispatch } from 'react-redux';
import { PLAN_TYPE } from '../../types';
import { useNavigate } from 'react-router-dom';
import Logo from 'app/components/Icons/Logo';

export default function TeamOnBoarding() {
    const [activeIndex, setActiveIndex] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onProceed = () => {
        if(activeIndex > 2) {
            dispatch(actions.saveTeamRequest({
                callback: () => navigate('/app/home')
            }));
            return;
        };
        setActiveIndex(activeIndex + 1);
    }

    const onComplete = (plan: PLAN_TYPE) => {
        dispatch(actions.setTeamFormValue({
            name: 'plan',
            value: plan,
        }));
        onProceed();
    }

    return (
        <div className="container min-h-[690px] flex flex-col justify-start max-w-[722px]">
            <div className='flex flex-col items-center gap-[20px]'>
                <Logo size={72}  />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Create new team</h1>
            </div>
            <TabNavigation activeIndex={activeIndex} />
            {
                activeIndex === 1 &&
                <FormOne onProceed={onProceed} />
            }
            {
                activeIndex === 2 &&
                <FormTwo onProceed={onProceed} />
            }
            {
                activeIndex === 3 &&
                <Pricing onProceed={onComplete} />
            }
        </div>
    )
}
