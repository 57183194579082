import Pill from 'app/components/Pill'
import Avatar from 'app/containers/Features/components/Avatar'
import React from 'react'
import descriptionIcon from "assets/svgs/ic_description.svg"
import workflowIcon from "assets/svgs/ic_tree.svg"
import attachmentIcon from "assets/svgs/ic_attachment.svg"
import historyIcon from "assets/svgs/ic_checklist.svg"
import priorityIcon from "assets/svgs/ic_flag_green.svg"
import { Ticket } from '../../../../types'
import { getTicketIcon } from '../helpers'
import { useQueryState } from 'nuqs'

export default function Row({
    ticket
}: {
    readonly ticket: Ticket
}) {
    const [, setTid] = useQueryState('tid');
    return (
        <tr onClick={()=>setTid(ticket.id)} className="h-[36px] border-b border-[#E9ECF2] cursor-pointer group hover:bg-[#E7F1FF]">
            <td className="p-[4px] max-w-[739px]">
                <div className="flex flex-row items-center gap-[4px]">
                    <div className="min-w-[20px]">
                        <img src={getTicketIcon(ticket?.type)} alt="task-icon" className="size-[20px]" />
                    </div>
                    <span className="text-sm leading-[18px] font-normal text-black">
                        {ticket?.id}
                    </span>
                    <div className="text-sm leading-[18px] font-normal text-black text-ellipsis overflow-hidden whitespace-nowrap">
                        {ticket?.name}
                    </div>
                    <div className="min-w-[16px]">
                        <img src={descriptionIcon} alt="task-icon" className="size-[16px]" />
                    </div>
                    <div className="flex flex-row items-center flex-grow">
                        <div className="min-w-[16px]">
                            <img src={workflowIcon} alt="task-icon" className="size-[16px]" />
                        </div>
                        <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{ticket?.membersCount}</div>
                    </div>
                    <div className="flex flex-row items-center flex-grow">
                        <div className="min-w-[16px]">

                            <img src={attachmentIcon} alt="task-icon" className="size-[16px]" />
                        </div>
                        <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{ticket?.attachmentsCount}</div>
                    </div>
                    <div className="flex flex-row items-center flex-grow">
                        <div className="min-w-[16px]">
                            <img src={historyIcon} alt="task-icon" className="size-[16px]" />
                        </div>
                        <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{`${ticket?.completedCount}/${ticket?.totalCount}`}</div>
                    </div>
                    <div className="flex flex-row items-center gap-[4px]">
                        {
                            ticket?.labels.map((label) => (
                                <Pill key={label.name} backgroundColor={label.backgroundColor} textColor={label.color} name={label.name} />
                            ))
                        }
                    </div>
                </div>
            </td>
            <td className="p-[4px]">
                <Pill backgroundColor={ticket?.status?.backgroundColor} className="px-[16px] py-[8px] w-[109px] text-sm leading-[21px] font-normal" textColor="#FFFFFF" name={ticket?.status.name} />
            </td>
            <td className="p-[4px]">
                <Avatar className="!min-w-[190px]" color={ticket?.assignee?.color} name={ticket?.assignee?.name} />
            </td>
            <td className="p-[4px]">
                <div className="flex flex-row items-center gap-[4px] min-w-[109px]">
                    <div className="min-w-[17px]">
                        <img src={priorityIcon} alt="task-icon" className="size-[17px]" />
                    </div>
                    <span className="text-sm leading-[21px] font-normal text-black">
                        {ticket?.priority}
                    </span>
                </div>
            </td>
            <td className="p-[4px] min-w-[107px]">
                <span className="text-sm leading-[21px] font-normal text-black">
                    {ticket?.dueDate}
                </span>
            </td>
        </tr>
    )
}
