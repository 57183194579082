import Input from 'app/components/Input'
import React from 'react'
import plus from 'assets/svgs/ic_plus_primary.svg'
import Button from 'app/components/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeamForm } from 'app/containers/OnBoarding/redux/selector';
import { actions } from 'app/containers/OnBoarding/redux/slice';
import { validateTeamFormMembers } from 'app/containers/OnBoarding/helpers';
import { Select } from 'app/components/Select';
import { Roles } from 'app/containers/OnBoarding/data';

interface FormTwoProps {    
    readonly onProceed: () => void;
}

export default function FormTwo({ onProceed }: FormTwoProps) {
    const { members } = useSelector(selectTeamForm);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const errors = validateTeamFormMembers(members);
        if (errors.length > 0) {
            errors.forEach((error) => {
                const name = error.name.split('-')[0];
                const index = parseInt(error.name.split('-')[1]);
                const _ = {index, name, error: error.error}
                dispatch(actions.updateTeamMemberError(_));
            });
            return;
        }
        onProceed();
    };

    return (
        <div className='flex flex-col mb-[56px]'>
            <h5 className='text-lg text-center text-black font-medium leading-[27px] mb-[16px]'>Add members</h5>
            {/* Add member and role */}
            {members?.map((member, index) => (
                <div key={index} className='flex flex-row gap-[16px] w-full mb-[12px]'>
                    <Input 
                        containerClassName="w-full" 
                        label='Member' 
                        id={`member-${index}`} 
                        value={member.name.value}
                        onChange={(e) => dispatch(actions.updateTeamMember({index, name: 'name', value: e.target.value}))}
                        error={member.name.error}
                    />
                    <Select 
                        options={Roles}
                        containerClassName="w-full" 
                        placeholder="Choose role"
                        label='Role' 
                        id={`role-${index}`} 
                        value={member.role.value}
                        error={member.role.error}
                        onChange={(value) => dispatch(actions.updateTeamMember({index, name: 'role', value}))}
                    />
                </div>
            ))}
            <button 
                className='flex flex-row gap-[6px] items-center w-fit'
                onClick={() => dispatch(actions.addTeamMember())}
            >
                <img src={plus} alt="plus" width={16} height={16} />
                <p className='text-sm text-primary font-medium leading-[21px] mb-0 mt-1'>Member</p>
            </button>
            <div className='flex flex-col justify-center items-center gap-[16px] mt-6'>
                <Button 
                    className='w-[390px] h-[56px]'
                    onClick={handleSubmit}
                >
                    Continue
                </Button>
                <Link onClick={onProceed} to="#" className='text-black font-normal leading-[24px] text-center mb-0 mt-1'>
                    Skip for now
                </Link>
            </div>
        </div>
    )
}
