import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import DropdownIcon from 'app/components/Icons/DropdownIcon'
import EpicIcon from 'app/components/Icons/EpicIcon'
import ComponentIcon from 'app/components/Icons/ComponentIcon'
import IconText from 'app/components/IconText'
import React, { useState } from 'react'
import StoryIcon from 'app/components/Icons/StoryIcon'
import SprintIcon from 'app/components/Icons/SprintIcon'
import ReleaseIcon from 'app/components/Icons/ReleaseIcon'
import CheckIcon from 'app/components/Icons/CheckIcon'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import { PlanningTypes } from '../types'

const OPTIONS = [
    {
        label: 'Components',
        value: PlanningTypes.COMPONENTS,
        icon: <ComponentIcon size={16} color="#79808A" />
    },
    {
        label: 'Epics',
        value: PlanningTypes.EPICS,
        icon: <EpicIcon size={16} color="#79808A" />
    },
    {
        label: 'Stories',
        value: PlanningTypes.STORIES,
        icon: <StoryIcon size={16} color="#79808A" />
    },
    {
        label: 'Sprints',
        value: PlanningTypes.SPRINT,
        icon: <SprintIcon size={16} color="#79808A" />
    },
    {
        label: 'Releases',
        value: PlanningTypes.RELEASES,
        icon: <ReleaseIcon size={16} color="#79808A" />
    }
]

const getPlanningTypeIcon = (type: PlanningTypes) => {
    switch (type) {
        case PlanningTypes.COMPONENTS:
            return <ComponentIcon size={16} color="#253858" />
        case PlanningTypes.EPICS:
            return <EpicIcon size={16} color="#253858" />
        case PlanningTypes.STORIES:
            return <StoryIcon size={16} color="#253858" />
        case PlanningTypes.SPRINT:
            return <SprintIcon size={16} color="#253858" />
        case PlanningTypes.RELEASES:
            return <ReleaseIcon size={16} color="#253858" />
        default:
        return null
    }
}

export default function Filter() {
    const [open, setOpen] = useState(false)
    const [searchParams] = useSearchParams()
    const planType = searchParams.get('plan-type') as PlanningTypes ?? PlanningTypes.EPICS
    const pathname = useLocation()?.pathname
    return (
        <div className="flex flex-col gap-[6px]">
            <DropdownMenu
                open={open}
                onOpenChange={setOpen}
            >
                <DropdownMenuTrigger asChild>
                    <button className="bg-white w-full border border-[#E9ECF2] rounded-[4px] px-[8px] pl-[16px] py-[12px] flex flex-col gap-[10px] cursor-pointer hover:bg-[#ECEEF2]">
                        <div className="flex flex-row items-center gap-[4px] justify-between w-full">
                            <IconText 
                                icon={getPlanningTypeIcon(planType)}
                                text={planType}
                                className="capitalize"
                            />
                            <button className="size-[18px]">
                                <DropdownIcon size={18} />
                            </button>
                        </div>
                    </button>
                </DropdownMenuTrigger>

                <DropdownMenuContent
                    className="py-[10px] !w-[333px] bg-danger z-10 !bg-white rounded-[4px] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] border border-[#E9ECF2]" // move to the right by 50%
                >
                    {
                        OPTIONS.map((option) => (
                            <DropdownMenuItem 
                                key={option.value}
                                className="!w-[100%] cursor-pointerhover:outline-none text-sm leading-[21px] font-normal text-secondary hover:bg-[#F4F5F7]"
                                onClick={()=>setOpen(false)}
                            >
                                <Link to={`${pathname}?plan-type=${option.value}`} className="flex flex-row justify-between gap-[6px] items-center px-4 pr-[8px] py-[12px] ">
                                    <div className="flex flex-row items-center gap-[4px]">
                                        {option.icon}
                                        <span className="text-sm leading-[21px] text-secondary font-medium mt-[2px]">{option.label}</span>
                                    </div>
                                    {
                                        option.value === planType && (
                                            <button className="size-[18px]">
                                                <CheckIcon color="#5A8DF1" />
                                            </button>
                                        )
                                    }
                                </Link>
                            </DropdownMenuItem>
                        ))
                    }
                </DropdownMenuContent>
            </DropdownMenu>
            <p className="text-sm leading-[21px] text-secondary">{`Drag and drop tickets to add them to ${planType}`}</p>
        </div>
    )
}