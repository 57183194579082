/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "sanitize.css/sanitize.css";
import "./index.css";
import "./locales/i18n";
import "react-toastify/dist/ReactToastify.css";
import { App } from "./app";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { configureAppStore } from "./store/configureStore";
import { createRoot } from "react-dom/client";
import { NuqsAdapter } from 'nuqs/adapters/react'

const store = configureAppStore();
const MOUNT_NODE = document.getElementById("root") as HTMLElement;

interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <Provider store={store}>
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      closeButton={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      icon={false}
      toastClassName="bg-black w-[328px] p-[10px] !rounded-[8px] h-fit !min-h-[25px]"
      bodyClassName="text-white !p-0 !m-0 text-xs leading-[16px] h-[25px] font-mona"
    />
    <HelmetProvider>
      <React.StrictMode>
        <NuqsAdapter>
          <Component />
        </NuqsAdapter>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);

const render = (Component: typeof App) => {
  const root = createRoot(MOUNT_NODE);
  root.render(<ConnectedApp Component={Component} />);
};

if (module?.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module?.hot.accept(["./", "./locales/i18n"], () => {
    const root = createRoot(MOUNT_NODE);
    root.unmount();
    const App = require("./app").App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// serviceWorker.unregister();
