import basecamp from 'assets/svgs/ic_basecamp.svg';
import monday from 'assets/svgs/ic_monday.svg';
import trello from 'assets/svgs/ic_trello.svg';
import jira from 'assets/svgs/ic_jira.svg';
import asana from 'assets/svgs/ic_asana.svg';
import notion from 'assets/svgs/ic_notion.svg';
import excel from 'assets/svgs/ic_excel.svg';

export const companySizes = [
    {
        label: '1-10',
        src: ''
    },
    {
        label: '11-25',
        src: ''
    },
    {
        label: '26 - 250',
        src: ''
    },
    {
        label: '251 - 500',
        src: ''
    },
    {
        label: '501 - 2000',
        src: ''
    },
    {
        label: '2001+',
        src: ''
    },
    {
        label: 'I don’t know',
        src: ''
    }
]

export const referalSources = [
    {
        label: 'Friend / Colleague',
        src: ''
    },
    {
        label: 'Facebook / Instagram',
        src: ''
    },
    {
        label: 'TV / Streaming (Hulu, NBC, etc.)',
        src: ''
    },
    {
        label: 'LinkedIn',
        src: ''
    },
    {
        label: 'YouTube',
        src: ''
    },
    {
        label: 'TikTok',
        src: ''
    },
    {
        label: 'Billboard',
        src: ''
    },
    {
        label: 'Search Engine (Google, Bing, etc.)',
        src: ''
    },
    {
        label: 'Podcast / Radio',
        src: ''
    },
    {
        label: 'Other',
        src: ''
    }
]

export const usedToolsList = [
    {
        label: 'Basecamp',
        src: basecamp
    }, 
    {
        label: 'Todolist',      
        src: monday
    }, 
    {
        label: 'Monday',
        src: monday
    }, 
    {
        label: 'Trello',
        src: trello
    }, 
    {
        label: 'Jira',
        src: jira
    }, 
    {
        label: 'Asana',
        src: asana
    }, 
    {
        label: 'Wrike',
        src: monday
    }, 
    {
        label: 'Notion',
        src: notion
    }, 
    {
        label: 'Excel&CSV',
        src: excel
    }, 
    {
        label: 'Confluence',
        src: monday
    }
]

export const Roles = [
    {
        label: 'Team Owner',
        value: 'Team Owner',
        description: 'Has unrestricted access to all features, including billing and team deletion.'
    },
    {
        label: 'Administrator',
        value: 'Administrator',
        description: 'Has comprehensive control over all aspects of the system except billing and team deletion.    '
    },
    {
        label: 'Project Manager',
        value: 'Project Manager',
        description: 'Oversees specific projects with full control, including access to team-wide timesheets, planning modules, and the ability to report time for all users.'
    },
    {
        label: 'Scrum Master',
        value: 'Scrum Master',
        description: 'Facilitates Agile processes with permissions to create sprints, access team-wide timesheets, and use planning modules.'
    }
]