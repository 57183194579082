/**
 * Boards selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.AppState || initialState;

export const selectIsAuthenticated = createSelector(
    [selectDomain],
    (AppState) => AppState.isAuthenticated,
);

export const selectAuthorizedAccounts = createSelector(
    [selectDomain],
    (AppState) => AppState.authorizedAccounts,
);

export const selectUser = createSelector(
    [selectDomain],
    (AppState) => AppState.user,
);
