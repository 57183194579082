import React from 'react'

interface ProgressProps {
    readonly progress: number;
    readonly containerClassName?: string;
    readonly progressClassName?: string;
}

export default function Progress({ progress, containerClassName, progressClassName="bg-gradient-to-r from-[#65ACFF] to-[#8ADCE2]" }: ProgressProps) {
  return (
    <div className={`relative bg-gray-100 rounded-[40px] h-[16px] w-full ${containerClassName}`}>
        <div 
          className={`absolute top-0 left-0 h-full rounded-[40px] transition-all duration-500 ease-in-out ${progressClassName}`}
          style={{ width: `${progress}%` }}
        ></div>
    </div>
  )
}
