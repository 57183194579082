import React from 'react'

interface RightCaretIconProps {
    color?: string
    size?: number
}

export default function RightCaretIcon({ color = "#79808A", size = 24 }: RightCaretIconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/arrows/down">
                <path id="Vector" d="M10 7L15 12L10 17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>  
    )
}
