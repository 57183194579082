import React from 'react'

export default function StatusIcon({
    size = 16,
    color="#A394FB"
}: {
    readonly size?: number
    readonly color?: string
}) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 1000001473">
                <circle id="Ellipse 1012" cx="8" cy="8" r="6.85" transform="rotate(-180 8 8)" stroke={color} strokeWidth="1.3"/>
                <circle id="Ellipse 1013" cx="8" cy="8" r="2.5" transform="rotate(-180 8 8)" fill={color}/>
            </g>
        </svg>

    )
}