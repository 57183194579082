import React from 'react'

interface PillProps {
    name: string;
    className?: string;
    onClick?: () => void;
    backgroundColor?: string;
    textColor?: string;
}

const Pill = ({ name, className, onClick, backgroundColor, textColor }: PillProps) => {
    return (
        <button style={{ backgroundColor, color: textColor }} className={`px-[10px] py-[2px] rounded-[4px] text-[10px] leading-[18px] font-medium text-center text-nowrap ${className}`} onClick={onClick}>
            {name}
        </button>
    )
}

export default Pill;