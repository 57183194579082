import React from 'react'
import send from '../../../../assets/svgs/ic_send.svg';
import { Link } from 'react-router-dom';
import rightArrow from '../../../../assets/svgs/ic_right_arrow_primary.svg';

export default function RecoverySuccess() {
  return (
    <div className='flex flex-col items-center gap-[56px]'>
        <div className='flex flex-col items-center gap-[20px]'>
            <img sizes='72px' src={send} alt="Send" />
            <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Recovery link sent!</h1>
        </div>
        <div className='flex flex-col items-center gap-[20px]'>
            <h1 className="text-[28px] leading-[27px] font-normal text-center">Now check your email</h1>
            <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>or <Link to="/auth/login" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Log in <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
        </div>
        <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>Don’t have an account? <Link to="/auth/signup" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Sign up <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
    </div>
  )
}
