import { FormElement, FormError } from "app/types";
import { PLAN_TYPE, ProfileForm, TeamMember } from "./types";

export const validateProfileForm = (form: Omit<ProfileForm, 'isLoading'>) => {
    const errors: FormError[] = [];

    if (!form.name.value) {
        errors.push({
            name: 'name',
            error: 'Name is required'
        });
    }

    if (!form.password.value) {
        errors.push({
            name: 'password',
            error: 'Password is required'
        });
    }

    if(form.password.value.length < 8) {
        errors.push({
            name: 'password',
            error: 'Password must be at least 8 characters'
        });
    }

    if (!form.confirmPassword.value) {
        errors.push({
            name: 'confirmPassword',
            error: 'Confirm password is required'
        });
    }

    if (form.password.value !== form.confirmPassword.value) {
        errors.push({
            name: 'confirmPassword',
            error: 'Passwords do not match'
        });
    }

    return errors;
}

export const validateTeamFormName = (name: FormElement) => {
    const errors: FormError[] = [];

    if (!name.value) {
        errors.push({
            name: 'name',
            error: 'Name is required'
        });
    }

    return errors;
}

export const validateTeamFormMembers = (members: TeamMember[]) => {
    const errors: FormError[] = [];

    if (members.length > 0) {
        members.forEach((member, index) => {
            if (!member.name.value) {
                errors.push({
                    name: `name-${index}`,
                    error: 'Member name is required'
                });
            }
            if (!member.role.value) {
                errors.push({
                    name: `role-${index}`,
                    error: 'Member role is required'
                });
            }
        });
    }

    return errors;
}   

export const validateTeamFormPlan = (plan: PLAN_TYPE) => {
    const errors: FormError[] = [];

    if (!plan) {
        errors.push({
            name: 'plan',
            error: 'Plan is required'
        });
    }
}