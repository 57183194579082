import { TicketType } from "../../../../types"
import bugIcon from "assets/svgs/ic_task_bug.svg"
import storyIcon from "assets/svgs/ic_task_book.svg"
import taskIcon from "assets/svgs/ic_task_check.svg"
import epicIcon from "assets/svgs/ic_epic.svg"

export const getTicketIcon = (type: TicketType) => {
    switch (type) {
        case TicketType.BUG:
            return bugIcon
        case TicketType.STORY:
            return storyIcon
        case TicketType.TASK:
            return taskIcon
        case TicketType.EPIC:
            return epicIcon
        default:
        return taskIcon
    }
}