import * as React from "react"
import { DayPicker } from "react-day-picker"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={className}
      classNames={{
        months: "flex flex-col justify-center w-full",
        caption: "flex justify-center relative items-center",
        caption_label: "text-[11px] text-center font-medium text-secondary",
        weekday: "text-[11px] text-center font-normal text-gray-300 !h-[24px] !w-[24px] !min-w-[24px] !min-h-[24px]",
        day: "!h-[24px] !w-[24px] !min-w-[24px] !min-h-[24px] text-center text-[11px] leading-[18px] !font-normal p-0 relative",
        day_today: "!bg-primary !text-white !rounded-full",
        selected: "!bg-primary !text-white !rounded-full",
        day_outside: "text-secondary",
        day_disabled: "!opacity-50",
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
