import React from "react"
import CalendarIcon from "../Icons/CalendarIcon"
import { format } from "date-fns"
import { Calendar } from "../Calendar"
import { Popover, PopoverTrigger, PopoverContent } from "../Popover"

interface DatePickerProps {
  label?: string
  value: Date
  onChange: (date: Date) => void
  disabled?: boolean
  placeholder?: string
  className?: string
  error?: string
  labelClassName?: string
  innerContainerClassName?: string
  align?: "start" | "center" | "end"
}

export default function DatePicker({
  label,
  value,
  onChange,
  disabled,
  className,
  error,
  labelClassName,
  innerContainerClassName,
  align = "start",
  placeholder="Choose date..."
}: DatePickerProps) {
    const [open, setOpen] = React.useState(false)
 
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className="relative">
            <div className={`flex flex-col gap-[6px] ${innerContainerClassName}`}>
              {
                label &&
                <label className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}>{label}</label>
              }
              <button
                disabled={disabled}
                className={`flex items-center justify-between text-sm leading-[21px] gap-[8px] h-[48px] flex-row border rounded-[4px] py-[16px] px-[10px] ${className} ${open ? '!border-[#79808A]' : 'border-[#E9ECF2]'} ${error && 'border-danger'} ${disabled && 'opacity-50'}`}
              >
                {value ? format(value, "dd MMM, yyyy") : <span>{placeholder}</span>}
                <CalendarIcon 
                  size={20} 
                  color={error ? "#EB5757" : "#79808A"}
                />
              </button>
            </div>
          
            {
              error &&
              <span className="text-[11px] leading-[18px] text-danger absolute bottom-[-18px]">{error}</span>
            }
          </div>
        </PopoverTrigger>
        <PopoverContent align={align} className={`w-[222.798px] !px-4 !py-3 bg-white border border-[#E9ECF2]`}>
          <Calendar
            mode="single"
            selected={value}
            onSelect={onChange}
            required={true}
          />
        </PopoverContent>
      </Popover>
    )
}