import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState } from "../types";
import { FormError } from "app/types";
import { set } from "lodash";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
    loginForm: {
        email: {
            value: "",
            error: "",
        },
        password: {
            value: "",
            error: "",
        },
        isLoading: false,
    },
    forgotPasswordForm: {
        email: {
            value: "",
            error: "",
        },
        isLoading: false,
    },
    signupForm: {
        email: {
            value: "",
            error: "",
        },
        isLoading: false,
    },
    verifyEmailForm: {
        code: {
            value: "",
            error: "",
        },
        isLoading: false,
    },
};

const formSlice = createSlice({
  name: "AuthState",
  initialState,
  reducers: {
    setLoginFormErrors: (state, action: PayloadAction<FormError[]>) => {
        action.payload.forEach((error) => {
            set(state.loginForm, `${error.name}.error`, error.error);
        });
    },
    resetLoginFormError: (state, action: PayloadAction<string>) => {
        state.loginForm[action.payload].error = "";
    },
    loginRequest: (state, action: PayloadAction<{email: string, password: string}>) => {
        state.loginForm.isLoading = true;
        state.loginForm.email.value = action.payload.email;
        state.loginForm.password.value = action.payload.password;
    },
    loginRequestComplete: (state) => {
        state.loginForm.isLoading = false;
    },
    setForgotPasswordFormErrors: (state, action: PayloadAction<FormError[]>) => {
        action.payload.forEach((error) => {
            state.forgotPasswordForm[error.name].error = error.error;
        });
    },
    resetForgotPasswordFormError: (state) => {
        state.forgotPasswordForm.email.error = "";
    },
    forgotPasswordRequest: (state, action: PayloadAction<{email: string; callback?: () => void}>) => {
        state.forgotPasswordForm.isLoading = true;
        state.forgotPasswordForm.email.value = action.payload.email;
    },
    forgotPasswordRequestComplete: (state) => {
        state.forgotPasswordForm.isLoading = false;
    },
    setSignupFormErrors: (state, action: PayloadAction<FormError[]>) => {
        action.payload.forEach((error) => {
            set(state.signupForm, `${error.name}.error`, error.error);
        });
    },
    resetSignupFormError: (state) => {
        state.signupForm.email.error = "";
    },
    signupRequest: (state, action: PayloadAction<{email: string, callback?: () => void}>) => {
        state.signupForm.isLoading = true;
        state.signupForm.email.value = action.payload.email;
    },
    signupRequestComplete: (state) => {
        state.signupForm.isLoading = false;
    },
    setVerifyEmailFormError: (state, action: PayloadAction<string>) => {
        state.verifyEmailForm.code.error = action.payload;
    },
    resetVerifyEmailFormError: (state) => {
        state.verifyEmailForm.code.error = "";
    },
    verifyEmailRequest: (state, action: PayloadAction<{code: string, callback?: () => void}>) => {
        state.verifyEmailForm.isLoading = true;
        state.verifyEmailForm.code.value = action.payload.code;
    },
    verifyEmailRequestComplete: (state) => {
        state.verifyEmailForm.isLoading = false;
    },
  },
});

export const { actions, reducer: authReducer, name: authSlice } = formSlice;
