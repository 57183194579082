"use client";
import React from 'react';
import Monthly from '../Monthly';
import Yearly from '../Yearly';
import { useSearchParams } from 'react-router-dom';
import { PLAN_TYPE } from 'app/containers/OnBoarding/types';


const Cards = ({
    onClick
}:{
    onClick: (plan: PLAN_TYPE) => void;
}) => {
    const [searchParams] = useSearchParams();
    const plan = searchParams.get('plan') ?? 'monthly';
    return (
        <div className="grid grid-cols-3 gap-[10px]">
            {
                plan === 'monthly' &&
                <Monthly onClick={onClick} />
            }
            {
                plan === 'yearly' &&
                <Yearly onClick={onClick} />
            }
        </div>
    );
}

export default Cards;
