import React, { useState } from 'react'
import Epic from '../components/Epic'
import EpicIcon from 'app/components/Icons/EpicIcon'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import AddEpicModal from '../../../components/AddEpicModal'

const EPICS = [
    {
        title: {
            name: "Tickets without epic",
            color: "#253858"
        },
        story: '-',
        task: '5',
        bug: '2',
        canceled: true,
    },
    {
        title: {
            name: "Feature Factory",
            color: "#9B51E0"
        },
        story: '2',
        task: '5',
        bug: '2',
        canceled: false,
    },
    {
        title: {
            name: "Integration Innovators",
            color: "#2F80ED"
        },
        story: '2',
        task: '5',
        bug: '2',
        canceled: false,
    },
]

export default function Epics() {
    const [isAddEpicModalOpen, setIsAddEpicModalOpen] = useState(false)
    return (
        <div className="flex flex-col gap-[10px]">
            {EPICS.map((epic, index) => (
                <Epic 
                    icon={<EpicIcon isCanceled={epic.canceled} color={epic.title.color} />}
                    key={index} 
                    title={epic.title.name} 
                    story={epic.story} 
                    task={epic.task} 
                    bug={epic.bug} 
                    color={epic.title.color}
                />
            ))}
            <button className="flex flex-row w-fit items-center gap-[4px]" onClick={() => setIsAddEpicModalOpen(true)}>
                <img src={addIcon} alt="add" className="size-[20px]" />
                <span className="text-sm leading-[24px] text-primary font-semibold mt-[2px] font-sans">Epic</span>
            </button>
            {
                isAddEpicModalOpen && (
                    <AddEpicModal onClose={() => setIsAddEpicModalOpen(false)} />
                )
            }
        </div>
    )
}