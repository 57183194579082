import Button from 'app/components/Button'
import DropdownIcon from 'app/components/Icons/DropdownIcon'
import PlusIcon from 'app/components/Icons/PlusIcon'
import React, { useState } from 'react'
import AddTaskModal from './AddTaskModal'

export default function CreateTaskButton() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    return (
        <div className="flex flex-col">
            <Button onClick={() => setIsModalOpen(true)} className="text-secondary !p-0 h-[42px] text-sm leading-[21px] font-normal flex flex-row items-center">
                <div className='flex flex-row items-center gap-[4px] px-[10px]'>
                    <PlusIcon color="#FFFFFF" />
                    <span className="text-sm leading-[21px] font-normal text-white mt-[2px]">Task</span>
                </div>
                <div className='flex flex-row items-center justify-center h-full gap-[4px] px-[8px] border-l border-[rgba(255,255,255,0.2)]'>
                    <DropdownIcon color="#FFFFFF" />
                </div>
            </Button>
            {
                isModalOpen && (
                    <AddTaskModal onClose={() => setIsModalOpen(false)} />
                )
            }
       </div>
    )
}
