import { FormError } from "app/types";
import { LoginForm, ForgotPasswordForm, SignupForm } from "./types";

export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const validateLoginForm = (loginForm: Omit<LoginForm, 'isLoading'>) => {
    const { email, password } = loginForm;
    const errors: FormError[] = [];

    if (!validateEmail(email.value)) {
        errors.push({
            name: 'email',
            error: 'Please enter a valid email address',
        });
    }
    if (password.value.length < 8) {
        errors.push({
            name: 'password',
            error: 'Password must be at least 8 characters',
        });
    }

    return errors;
}

export const validateForgotPasswordForm = (forgotPasswordForm: Omit<ForgotPasswordForm, 'isLoading'>) => {
    const { email } = forgotPasswordForm;
    const errors: FormError[] = [];

    if (!validateEmail(email.value)) {
        errors.push({
            name: 'email',
            error: 'Please enter a valid email address',
        });
    }

    return errors;
}

export const validateSignupForm = (signupForm: Omit<SignupForm, 'isLoading'>) => {
    const { email } = signupForm;
    const errors: FormError[] = [];

    if (!validateEmail(email.value)) {
        errors.push({
            name: 'email',
            error: 'Please enter a valid email address',
        });
    }

    return errors;
}

export const validateOtp = (otp: string) => {
    // OTP must be 6 digits
    const otpRegex = /^[0-9]{6}$/;
    return otpRegex.test(otp);
}

