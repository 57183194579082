import { format, formatDistance } from 'date-fns';

export const handleChange = (event, state, stateUpdater) => {
  const { name, value } = event.target;

  if (typeof state === 'object') {
    stateUpdater({ ...state, [name]: value });
  } else {
    stateUpdater(value);
  }
};

export const getDefaultHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
  };
};

export const removeBlankProperties = (obj: object) => {
  return JSON.parse(JSON.stringify(obj, replaceUndefinedOrNull));
};

export function replaceUndefinedOrNull(key, value) {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  return value;
}

/**
 * returns logged in user is
 * @param
 */

export function formatDuration(duration) {
  if (parseFloat(duration) > 0) {
    return (parseFloat(duration) / 60).toFixed(2) + ' min';
  }
  return '-';
}

export const dateFormatter = unformattedDate => {
  if (!unformattedDate) {
    return '-';
  }
  let formatDate = new Date(unformattedDate);
  const newDate = format(formatDate, 'dd MMM, yyyy');
  return newDate;
};

export const randomString=(length) =>{
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getInitials = (name: string) => {
  const nameParts = name.split(' ');
  const initials = nameParts.map(part => part[0]).join('');
  return initials;
}

export const formatDateDistance = (date) => {
  if (!date) {
    return '-';
  }
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  });
}