import React from 'react';
import { TableHeader } from './types';

interface TableProps {
  readonly headers: TableHeader[];
  readonly emptyText?: string;
  readonly children?: React.ReactNode;
  readonly headerClassName?: string;
  readonly tableClassName?: string;
}

export default function Table({ 
  headers,
  emptyText="There are no any boards",
  children=null,
  headerClassName,
  tableClassName
}: TableProps) {
  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full">
        <table className={`w-full bg-white border border-[#E9ECF2] ${tableClassName}`}>
          <thead>
            <tr className={`bg-[#F4F5F7] h-[52px] ${headerClassName}`}>
              {headers.map((header) => (
                <th key={header.id} style={{width: header?.width, textAlign: header?.align ?? "left"}} className={`px-4 pr-2 py-[12px] text-sm leading-[21px] font-normal text-secondary ${header?.className}`}>
                  {header.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-visible">
            {!children ? (
              <tr>
                <td colSpan={headers.length} className="text-sm leading-[21px] font-normal text-secondary text-center h-[52px]">
                  {emptyText}
                </td>
              </tr>
            ) : (
              // Render your data rows here when you have data
              children
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
