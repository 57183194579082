export interface BoardsState {
    boards: Board[];
    favorites: string[];
    qaBoards: string[];
    recents: string[];
}

export interface Board {
    id: string;
    boardName: string;
    teamName: string;
    projects: Project[];
    owner: Owner;
    createdAt: string;
    updatedAt: string;
    viewedAt: string;
}

export interface Project {
    id: string;
    projectName: string;
    color: string;
}

export interface Owner {
    id: string;
    name: string;
    online: boolean;
    color: string;
}

export enum TicketType {
    TASK = "task",
    BUG = "bug",
    STORY = "story",
    EPIC = "epic"
}

export interface Project {
  id: string;
  projectName: string;
  color: string;
}

export interface Owner {
  id: string;
  name: string;
  online: boolean;
  color: string;
}

export interface Label {
  name: string;
  color: string;
  backgroundColor: string;
}

export interface Status {
  name: string;
  backgroundColor: string;
}

export interface Ticket {
  name: string;
  id: string;
  labels: Label[];
  status: Status;
  assignee: Assignee;
  membersCount: number;
  attachmentsCount: number;
  completedCount: number;
  totalCount: number;
  priority: string;
  dueDate: string;
  type: TicketType;
}

export interface Assignee {
  name: string;
  color: string;
  online: boolean;
}

export interface TicketGroup {
  status: {
    name: string;
    color: string;
  };
  tickets: Ticket[];
}

export interface TicketHeader {
  name: string;
  id: string;
  className: string;
}

export enum SprintStatus {
  CURRENT = "current",
  PREVIOUS = "previous",
  NEXT = "next"
}

export type ContainerState = BoardsState;
