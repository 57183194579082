import React from 'react'
import { Board } from '../../../types'
import Avatar from 'app/containers/Features/components/Avatar'
import { dateFormatter, formatDateDistance } from 'utils/helpers'
import Options from './Options'
import { useNavigate } from 'react-router-dom'

interface RowProps {
    board: Board
    onArchive: () => void
    onDuplicate?: () => void
    onFavorite?: () => void
}

export default function Row({ board, onArchive }: RowProps) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/app/boards/${board.id}/board`)
    }
    return (
        <tr className="cursor-pointer border-b border-[#E9ECF2] hover:bg-[#ECEEF2] group" onClick={handleClick}>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D] flex items-center flex-row">
                <div className='flex items-center flex-row relative'>
                    <div className="flex flex-col items-center justify-center">
                        <Options 
                            onArchive={onArchive}
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        {board.boardName}
                    </div>
                </div>
            </td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">{board.teamName}</td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">{board.projects.map((project, index) => {
                return (
                    <span style={{ color: project.color }}>{project.projectName}<span className="text-[#333E4D]">{board.projects.length > 1 && index < board.projects.length - 1 && ', '}</span></span>
                )
            })}</td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">{formatDateDistance(board.viewedAt)}</td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">{formatDateDistance(board.updatedAt)}</td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">
                <Avatar name={board.owner.name} color={board.owner.color} />
            </td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">{dateFormatter(board.createdAt)}</td>
        </tr>
    )
}
